<div class="col-12 pt-4 d-flex" style="justify-content: center; align-items: center;">
    <div class="col-12 col-md-10 form">
        <div class="col-12 d-flex" style="justify-content: center; align-items: center; flex-wrap: wrap;">
            <div class="col-12 d-flex" style="justify-content: end;">
                <button type="submit" class="btn btn_dash p-2 px-4 btn d-flex" routerLink="/sidebar"
                    style="justify-content: center; align-items: center;">Go to Dashboard</button>
            </div>
            <div class="col-12 mt-2 head">
                Welkom bij het DialectAI-platform
            </div>
            <div class="col-12 pt-5 sub_head">
                Begin bij de basis
            </div>
            <div class="col-12 pt-4 d-flex"
                style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                <div class="col-12 col-md-6 d-flex" style="justify-content: start;">
                    <div class="col-12 col-md-11 gradient_card1 d-flex" style="align-items: end;">
                        <div class="col-12 desc">
                            <p class="sub_head_1">Quickstart Tutorial</p>
                            <p>Learn by building a quick sample app</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 pt-3 pt-md-0 d-flex" style="justify-content: start;">
                    <div class="col-12 col-md-11 gradient_card2 d-flex" style="align-items: end;">
                        <div class="col-12 desc">
                            <p class="sub_head_1">API Refernces</p>
                            <p>Expolre Some examples tasks</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>