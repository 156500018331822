import { Component } from '@angular/core';
import {
  codeResponse,
  codeListModels,
  getConversation,
} from '../documentation/documentation-model';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';
import { ToastrService } from 'src/app/services/toasterService';

@Component({
  selector: 'app-api-reference',
  templateUrl: './api-reference.component.html',
  styleUrls: ['./api-reference.component.scss'],
})
export class ApiReferenceComponent {
  selectedSectionName: string = 'Get Response';
  selectedSubItems: string = '';
  isCopied: boolean = false;
  copiedCodeSection: string = '';

  documentation = [
    { id: '01', name: 'Get Response' },
    { id: '02', name: 'Get Conversation' },
  ];

  codeResponse = codeResponse;
  codeListModels = codeListModels;
  getConversation = getConversation;

  indexResponse: number = 0;
  indexConversation: number = 0;

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    private toastrService: ToastrService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
  }

  toggleSubItems(name: string): void {
    if (this.selectedSectionName === name) {
      this.selectedSectionName = name;
    } else {
      this.selectedSectionName = name;
    }

    this.scrollToSection(this.selectedSectionName);
    this.selectedSubItems = '';
  }

  scrollToSection(sectionId: string): void {
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      const currentYOffset =
        window.pageYOffset || document.documentElement.scrollTop;
      const sectionTop =
        sectionElement.getBoundingClientRect().top + currentYOffset;
      if (sectionId === 'Get Response') {
        const yOffset = sectionTop - 140;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      } else {
        const yOffset = sectionTop - 60;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      }
    }

    this.selectedSubItems = sectionId;
  }

  copyCodeToClipboard(text: any, codeSectionId: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.isCopied = true;
    this.copiedCodeSection = codeSectionId;

    if (this.languageService.getLanguage() === 'en') {
      this.toastrService.success('Code Copied to clipboard!');
    } else {
      this.toastrService.success('Code gekopieerd naar klembord!');
    }

    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }

  setActiveResponse(index: number): void {
    this.indexResponse = index;
  }

  setActiveConversation(index: number): void {
    this.indexConversation = index;
  }

  // Function to handle the download action
  downloadPostmanCollection() {
    const postmanCollectionUrl =
      'assets/file/Dialect-AI-Api.postman_collection.json';

    // Creating a link element
    const link = document.createElement('a');

    // Setting the download attribute and href
    link.setAttribute('download', 'Dialect-AI-Api.postman_collection.json');
    link.href = postmanCollectionUrl;

    // Simulating a click to trigger the download
    document.body.appendChild(link);
    link.click();

    // Removing the link from the DOM
    document.body.removeChild(link);
  }
}
