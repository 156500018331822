<div class="col-12 p-4 pt-5 pb-5 d-flex" style="justify-content: center; flex-wrap: wrap;">
    <div class="col-12 d-flex" style="justify-content: center; align-items: center; flex-wrap: wrap;">
        <div class="col-12 col-md-11 col-lg-10">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-12 head">
                        {{ "Create Agent.Create your AI Agent" | translate }}
                    </div>
                    <div class="col-12 pt-2 desc" style="color: #5a5a5a;">
                        {{ "Create Agent.Create your AI Agent desc" | translate }}
                    </div>
                    <div class="col-12 pt-3 head" style="font-size: calc(var(--font-large));">
                        {{ "Create Agent.Give your AI Agent a name" | translate }}
                    </div>
                    <div class="col-12">
                        <input matInput class="form-control" formControlName="AgentName" name="AgentName" type="text"
                            placeholder="Voer hier de naam in van je Agent">
                    </div>
                    <mat-error class="mt-2 mb-2" style="font-size: calc(var(--font-medium));"
                        *ngIf="isSubmitted && errorControl['AgentName']['errors']?.['required']">
                        {{ "Create Agent.Agent name is required" | translate }}
                    </mat-error>
                    <div class="col-12 pt-3 head" style="font-size: calc(var(--font-large));">
                        {{ "Create Agent.Description of your AI Agent" | translate }}
                    </div>
                    <div class="col-12">
                        <textarea matInput class="form-control p-3" formControlName="AgentDesc" name="AgentDesc"
                            type="text"
                            placeholder="Vertel hier iets meer over de onderwerpen die jouw AI-model straks allemaal kan behandelen...."
                            rows="5"></textarea>
                    </div>
                    <mat-error class="mt-2 mb-2" style="font-size: calc(var(--font-medium));"
                        *ngIf="isSubmitted && errorControl['AgentDesc']['errors']?.['required']">
                        {{ "Create Agent.Agent description is required" | translate }}
                    </mat-error>
                    <div class="col-12 pt-3 head" style="font-size: calc(var(--font-large));">
                        {{ "Create Agent.Choose a model" | translate }}
                    </div>
                    <div class="col-12">
                        <select class="form-control p-3" formControlName="ModelName" name="ModelName" type="select">
                            <option value="">-- {{ "Create Agent.Select a model" | translate }} --</option>
                            <option *ngFor="let model of modelsArray" [value]="model.name">{{ model.name }}
                            </option>
                        </select>
                    </div>
                    <mat-error class="mt-2 mb-2" style="font-size: calc(var(--font-medium));"
                        *ngIf="isSubmitted && errorControl['ModelName']['errors']?.['required']">
                        {{ "Create Agent.Select a model is required" | translate }}
                    </mat-error>
                    <div class="col-12 pt-3 head" style="font-size: calc(var(--font-large));">
                        {{ "Create Agent.Select a Language" | translate }}
                    </div>
                    <div class="col-12">
                        <select class="form-control p-3" formControlName="AgentLanguage" name="AgentLanguage"
                            type="select">
                            <option value="">-- {{ "Create Agent.Select a Language" | translate }} --</option>
                            <option *ngFor="let language of languageArray" [value]="language.language">{{
                                language.language }}
                            </option>
                        </select>
                    </div>
                    <mat-error class="mt-2 mb-2" style="font-size: calc(var(--font-medium));"
                        *ngIf="isSubmitted && errorControl['AgentLanguage']['errors']?.['required']">
                        {{ "Create Agent.Select a language is required" | translate }}
                    </mat-error>
                    <div class="col-12 pt-3 head" style="font-size: calc(var(--font-large));">
                        Selecteer een taal
                    </div>
                    <div class="col-12">
                        <select class="form-control p-3" formControlName="Language" name="Language" type="select">
                            <option value="">-- Selecteer een taal --</option>
                            <option *ngFor="let language of languageArray" [value]="language.language">{{
                                language.language }}
                            </option>
                        </select>
                    </div>
                    <mat-error class="mt-2 mb-2" style="font-size: calc(var(--font-medium));"
                        *ngIf="isSubmitted && errorControl['Language']['errors']?.['required']">
                        Selecteer een taal is vereist
                    </mat-error>
                    <div class="col-12 pt-5 d-flex">
                        <button type="submit" class="btn p-2 form-btn" style="width: 40%;" [hidden]="loading"
                            [disabled]="loading">{{ "Create Agent.Add Agent" | translate }}</button>
                        <mat-spinner [hidden]="!loading" diameter="40" strokeWidth="5"></mat-spinner>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>