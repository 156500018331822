import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-invalid-files',
  templateUrl: './invalid-files.component.html',
  styleUrls: ['./invalid-files.component.scss']
})
export class InvalidFilesComponent {

  constructor(public dialogRef: MatDialogRef<InvalidFilesComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService, private languageService: LanguageService) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
  }

  checkFilesAgain() {
    this.dialogRef.close(true);
  }

  closeForm() {
    this.dialogRef.close();
  }
}
