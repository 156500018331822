import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/AuthService';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-create-agent',
  templateUrl: './create-agent.component.html',
  styleUrls: ['./create-agent.component.scss']
})
export class CreateAgentComponent {
  form: FormGroup;
  isSubmitted = false;
  loading = false;
  modelsArray: any[] = [];
  languageArray = [
    { "id": 0, "language": "Dutch" },
    { "id": 1, "language": "English" }
  ];

  constructor(public dialogRef: MatDialogRef<CreateAgentComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private authService: AuthService, private formBuilder: FormBuilder, private translate: TranslateService, private languageService: LanguageService) {
    this.translate.setDefaultLang(this.languageService.getLanguage());

    this.form = this.formBuilder.group({
      AgentName: [''],
      AgentDesc: [''],
      ModelName: [' '],
      AgentLanguage: [' ']
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      AgentName: ['', [Validators.required]],
      AgentDesc: ['', [Validators.required]],
      ModelName: ['', [Validators.required]],
      AgentLanguage: [this.languageService.getLanguage() === 'en' ? this.languageArray[1].language : this.languageArray[0].language, [Validators.required]]
    });

    this.authService.getUserModels().subscribe(
      (response) => {
        if (response.data.status === '202') {
          this.createModelsArray(response.models);
          this.form.patchValue({ ModelName: this.modelsArray[0]?.name || '' });
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );

  }

  get errorControl() {
    return this.form.controls;
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    const agentName = this.form.controls['AgentName'].value;
    const agentDesc = this.form.controls['AgentDesc'].value;
    const modelName = this.form.controls['ModelName'].value;
    const language = this.form.controls['AgentLanguage'].value === "Dutch" ? "nl" : "en";

    console.log(language)
    const selectedModel = this.modelsArray.find((model: { name: any; }) => model.name === modelName);

    if (selectedModel && this.form.status === 'VALID') {
      this.loading = true;
      const modelId = selectedModel.id;

      this.authService.AgentCreation(agentName, agentDesc, modelId, language).subscribe(
        (response) => {
          this.loading = false;
          this.form.reset();
          this.dialogRef.close();
        },
        (error) => {
          console.error('Agent Creation Error:', error);
          this.loading = false;
        }
      );
    }
  }

  private createModelsArray(models: any[]): void {
    const uniqueNames = new Set();
    this.modelsArray = models.reduce((result, model) => {
      if (!uniqueNames.has(model.name)) {
        uniqueNames.add(model.name);
        result.push({
          id: model.id,
          name: model.name,
          description: model.description,
          user_id: model.user_id,
          created_at: model.created_at
        });
      }
      return result;
    }, []);
  }


}
