import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class uploadFileService {
    private uploadedFiles: File[] = [];
    private uploadedFilesT: File[] = [];
    private uploadedUrls: string[] = [];

    private invalidFiles: any[] = [];

    constructor() { }

    setUploadedFiles(files: File[]) {
        this.uploadedFiles = files;
    }

    getUploadedFiles(): File[] {
        return this.uploadedFiles;
    }

    setUploadedFilesT(files: File[]) {
        this.uploadedFilesT = files;
    }

    getUploadedFilesT(): File[] {
        return this.uploadedFilesT;
    }

    setUploadedUrl(url: string[]) {
        this.uploadedUrls = url;
    }

    getUploadedUrls(): string[] {
        return this.uploadedUrls;
    }

    saveInvalidFiles(files: any[]) {
        this.invalidFiles = files;
    }

    getInvalidFiles() {
        return this.invalidFiles;
    }
}