<div class="container-main">
    <div class="col-12" style="flex: 1;">
        <div class="col-12 navbar d-flex" style="justify-content: center;">
            <div class="col-12 p-2 px-3 d-flex" style="justify-content: space-between;">
                <img class="logo" src="../../../assets/Logo-1-dark.png" alt="DialectAI" data-toggle="tooltip"
                    data-placement="right" title="Dashboard" routerLink="/sidebar">
                <span class="d-flex name-text" style="justify-content: end;">
                    <p class="pe-3 mb-0">{{userName}} <br>
                        <span style="font-size: calc(var(--font-small)); color: #888a91;">{{companyName}}</span>
                    </p>
                    <div class="circle">
                        <img class="profile_img" src="../../../assets/avatar-cartoon.png" matTooltipPosition="above"
                            [matTooltip]="'Profile'">
                    </div>
                </span>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 py-4 d-flex" style="justify-content: center;">
                    <div class="col-12 col-md-10 help_form d-flex"
                        style="justify-content: center; align-items: center;">
                        <div class="col-12 d-flex"
                            style="justify-content: center; align-items: center; flex-wrap: wrap;">
                            <div class="col-12 d-flex" style="justify-content: end;">
                                <button type="submit" class="btn p-2 px-4 btn d-flex" routerLink="/sidebar"
                                    style="justify-content: center; align-items: center;">Go to Dashboard</button>
                            </div>
                            <div class="col-12 pt-1">
                                <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                                    aria-label="breadcrumb">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item desc" style="font-size: calc(var(--font-small));">
                                            Help
                                            Center</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="col-12 mt-2 card_back1 p-4 py-5 px-md-5 d-flex"
                                style="justify-content: space-between; align-items: end; flex-wrap: wrap-reverse;">
                                <div class="col-md-6 col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12 sub_head" style="color: #FFFFFF;">
                                        Workspace
                                    </div>
                                    <div class="col-12 pt-2 desc" style="color: #FFFFFF;">
                                        See the same collection data in multiple, configurable ways.
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 set_img_back d-flex" style="align-items: center;">
                                    <img class="help_center_img" src="../../../assets/help_center.png"
                                        alt="help_center">
                                </div>
                            </div>
                            <div class="col-12 pt-4">
                                <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                                    <div class="col" *ngFor="let item of helpCenterCardArray; let i = index">
                                        <div class="col-12 card py-3 py-xxl-4"
                                            [ngClass]="{ 'card-hovered': isCardHovered === i }"
                                            (mouseenter)="onCardHover(i)" (mouseleave)="onCardHover(-1)"
                                            (click)="onSelectItem(item.id)">
                                            <div class="card-body">
                                                <div class="col-12 sub_head_1"
                                                    [ngClass]="{ 'card_text': isCardHovered === i }"
                                                    (mouseenter)="onCardHover(i)" (mouseleave)="onCardHover(-1)">
                                                    {{item.name}}
                                                </div>
                                                <div class="col-12 pt-1 desc"
                                                    [ngClass]="{ 'card_text': isCardHovered === i }"
                                                    (mouseenter)="onCardHover(i)" (mouseleave)="onCardHover(-1)">
                                                    {{item.desc}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 d-flex" style="align-items: end; flex: 1;">
        <div class="footer col-12" style="flex: 1;">
            <app-footer></app-footer>
        </div>
    </div>
</div>