import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss'],
})
export class DocsComponent {
  userName: string | undefined;
  companyName: string | undefined;
  activeSection: string = 'APIReference';

  languageMode: string = '';

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
    this.languageMode = this.languageService.getLanguage();
  }

  ngOnInit() {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      this.userName = user.name;
      this.companyName = user.company_name;
    }
  }

  showSection(section: string): void {
    this.activeSection = section;
    window.scrollTo(0, 0);
  }

  setLanguage(language: string) {
    this.languageService.setLanguage(language);
    this.languageMode = language === 'en' ? 'EN' : 'NL';
    this.translate.use(language);
  }
}
