<div class="col-12 d-flex" style="justify-content: center; align-items: center;">
    <div class="wrapper">
        <div class="sidebar">
            <div class="col-12 p-1 pt-3 pb-3" style="height: calc(100% - 75px); overflow-y: auto;">
                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-11 d-flex" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 mt-3 desc" style="font-weight: 600;">
                            GET STARTED
                        </div>
                        <div class="col-12" *ngFor="let item of documentation1; let i = index">
                            <div class="col-12 mt-3 nav-link" (click)="toggleSubItems(item.name)"
                                [class.active]="selectedSectionName === item.name && selectedSubItems === ''">
                                {{item.name}}
                            </div>
                            <div class="col-12 d-flex" *ngIf="selectedSectionName === item.name"
                                style="justify-content: end; flex-wrap: wrap;">
                                <div class="col-11 mt-2 nav-link" *ngFor="let subItem of item.subItems"
                                    (click)="scrollToSection(subItem)" [class.active]="selectedSubItems === subItem">
                                    {{subItem}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main_container">
            <div class="col-12 pt-4 pb-4 d-flex" style="justify-content: center; height: 100%; overflow-y: auto;">
                <div class="col-md-11 col-xl-10 col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-12 d-flex" style="justify-content: end;">
                        <button type="submit" class="btn btn_dash p-2 px-4 btn d-flex" routerLink="/sidebar"
                            style="justify-content: center; align-items: center;">Go to Dashboard</button>
                    </div>
                    <div class="col-12 pt-2 d-flex" id="Introduction" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            Introduction
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                The DialectAI API is versatile and can be harnessed for a variety of tasks involving
                                conversational data and generating text. Specifically tailored for chat
                                applications, the DialectAI API excels in comprehending and producing natural
                                language in conversational contexts. We offer a range of models with different
                                capabilities and pricing tiers, and we provide the flexibility to fine-tune custom
                                models to meet your specific chat-based requirements.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="Dialect Model"
                        style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            Dialect Model
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                DialectAI specializes in chat generation, utilizing advanced models that excel at
                                understanding and producing natural language in conversational contexts. Our models
                                respond to queries with text outputs, providing meaningful and contextually relevant
                                information. Queries serve as the input to our models, guiding their responses.
                                Crafting an effective query is akin to programming our models, often involving
                                instructions or illustrative examples to achieve desired outcomes. DialectAI's
                                models are applicable across a wide spectrum of tasks, including content creation,
                                conversation generation, creative writing, and more. For more information, refer to
                                our introductory guide and best practices for utilizing DialectAI's chat generation
                                capabilities.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="Pricing" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            Pricing
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                We present a range of DialectAI models, each equipped with distinct capabilities and
                                pricing structures. While exploring, we recommend opting for the base model that has
                                been provided as they tend to yield optimal outcomes. Once you have achieved
                                operational success, you can explore alternative models to achieve comparable
                                results with reduced latency and costs.
                            </p>
                            <p>
                                It's important to note that the cumulative number of tokens processed within a
                                singular request (both prompt and completion) should not surpass the maximum context
                                length of the chosen model. In most cases, this context length stands at 4,096
                                tokens or approximately 3,000 words. As a rough guideline, it's estimated that 1
                                token translates to roughly 4 characters or 0.75 words for English text.
                            </p>
                            <p>
                                Pricing follows a pay-as-you-go model, calculated per 1,000 tokens, and includes a
                                $5 credit that can be utilized during the initial 3 months.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="Closing" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            Closing
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                These concepts and techniques are invaluable for empowering you to construct your
                                own application. However, it's important to acknowledge that this basic illustration
                                offers only a glimpse of the immense possibilities at your disposal. The versatility
                                of the completions endpoint extends to addressing virtually any language processing
                                challenge, encompassing content generation and conversational tasks.
                            </p>
                            <p>
                                Nevertheless, it's essential to bear in mind a constraint: in most instances, a
                                singular API request can manage a maximum of 4,096 tokens, spanning both your prompt
                                and completion.
                            </p>
                            <p>
                                As you engage in more intricate tasks, you may find yourself desiring the capacity
                                to furnish a larger quantity of examples or context than can be accommodated within
                                a solitary prompt. This is where the fine-tuning API comes into play, serving as a
                                potent solution for advanced undertakings. Fine-tuning empowers you to offer
                                numerous examples, ranging from hundreds to thousands, to tailor a model precisely
                                for your unique use case.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="Fine Tuning" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            Fine Tuning
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                Fine-tuning offers the opportunity to extract enhanced performance from the models
                                accessible via the API by enabling:
                            </p>
                            <ul>
                                <li>Attainment of superior quality outcomes compared to prompt design</li>
                                <li>Capability to train on a larger number of examples than can be accommodated
                                    within a prompt</li>
                                <li>Conservation of tokens due to briefer prompts</li>
                                <li>Reduction in request latency</li>
                            </ul>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="The Power of Auto Fine-Tuning">
                            The Power of Auto Fine-Tuning
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                DialectAI presents a revolutionary offering. Utilizing a data file, be it a PDF, text
                                file, CSV, or a URL, DialectAI's automated fine-tuning comes to the forefront. Our
                                sophisticated system extracts pertinent information from the sources you provide and
                                promptly commences the fine-tuning procedure, all without the need for manual
                                intervention. The outcome? Your very own personalized AI model, meticulously crafted to
                                meet your specifications, primed to produce content that resonates with your unique
                                communication style.
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1"
                            id="Revolutionizing the Path from Data to Distinct Model: Unveiling the Journey">
                            Revolutionizing the Path from Data to Distinct Model: Unveiling the Journey
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                <span style="font-weight: bold;">Streamlined Data Integration:</span>
                                <span class="ms-1">
                                    Seamlessly submit your data file, and let DialectAI's advanced algorithms handle the
                                    intricacies. Our platform deftly navigates through PDFs, text files, CSVs, and URLs,
                                    extracting pivotal insights to construct an all-encompassing training dataset.
                                </span>
                            </p>
                            <p>
                                <span style="font-weight: bold;">Effortless Automated Fine-Tuning:</span>
                                <span class="ms-1">
                                    Once your data resides within our system, DialectAI's automated fine-tuning
                                    enchantment commences. Building upon the bedrock of OpenAI's technology, we set your
                                    data in motion, refining the foundational model to harmonize with your precise
                                    context and requisites.
                                </span>
                            </p>
                            <p>
                                <span style="font-weight: bold;">Your Model, Crafted Your Way:</span>
                                <span class="ms-1">
                                    With the culmination of the auto fine-tuning endeavor, behold the inception of your
                                    tailored AI model. Free from complexities and intricate procedures, the model now
                                    rests at your fingertips, armed and ready to craft content that echoes your
                                    unparalleled voice.
                                </span>
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Smooth Incorporation and Application">
                            Smooth Incorporation and Application
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                Incorporating your freshly developed AI model into your software is effortless.
                                DialectAI smoothly incorporates with OpenAI's Completions API, guaranteeing fluid
                                exchanges and outstanding execution. The potentials are boundless – create text,
                                optimize language handling, and enhance your user encounter with content customized to
                                your field.
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Revolutionizing Customization: DialectAI's Vision">
                            Revolutionizing Customization: DialectAI's Vision
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                At DialectAI, our groundbreaking perspective on auto fine-tuning resonates harmoniously
                                with our overarching vision of democratizing AI customization. We hold a firm belief
                                that each user ought to wield the authority to craft a bespoke AI model, all without the
                                convolutions commonly associated with conventional fine-tuning methods. With DialectAI
                                at your disposal, you're not merely acquiring an AI model; rather, you're embracing a
                                dynamic instrument primed to harmonize with your language nuances and contextual
                                intricacies. This, in turn, unlocks portals to uncharted realms of innovation,
                                propelling you toward new horizons of creative exploration.
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1"
                            id="Initiating Your AI Voyage: Step into the Future with DialectAI">
                            Initiating Your AI Voyage: Step into the Future with DialectAI
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                Are you prepared to set forth on a voyage of AI customization? Allow DialectAI to be
                                your compass as we navigate the extraordinary realm of auto fine-tuning. From seamlessly
                                integrating your data to optimizing model usage, our platform simplifies every step of
                                the journey, furnishing you with the long-awaited edge in the realm of AI. Uncover the
                                forthcoming era of AI with DialectAI – a domain where customization seamlessly unfolds,
                                innovation knows no confines, and your distinct voice reverberates with amplified
                                potency.
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Preparing Data">
                            Preparing Data
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                We allow you to fine tune the base model by giving data in any form, a few type of
                                data that you can send to train model are pdf file, excel file, txt file, docx file
                                and you can even give URL of any website you want to have data from, what our model
                                will do in this case is that it will scrape the given website and then create a
                                excel file of the type of data that has to be passed to the model for training.
                            </p>
                            <p>
                                All of these inputs files once processed will produce an Excel file which will be
                                sent through the next API for creating your custom model.
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Creating the Model">
                            Creating the Model
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                Once you have processed the data and have an Excel file. Now you need to send this
                                file to the API for training.
                            </p>
                            <p>
                                Executing the API mentioned above accomplishes a series of tasks:
                            </p>
                            <ul>
                                <li>Uploading the file using the files API (or utilizing an already-uploaded file)
                                </li>
                                <li>Initiating the creation of a fine-tune job</li>
                                <li>Continuously streaming events until the job completion (this duration varies
                                    from minutes to potentially hours, particularly if there's a substantial job
                                    queue or your dataset is sizable)</li>
                            </ul>
                            <p>
                                Each fine-tuning job commences from a foundational model, with the default being
                                curie. The selection of the model has an impact on both the model's performance and
                                the expenses associated with operating your fine-tuned model.
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="During Model Training">
                            During Model Training
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                Once you have initiated a fine-tune job, it might require a certain duration to
                                finish. Your job could potentially be in line behind other jobs within our system's
                                queue, and the training process of our model can span minutes to hours, contingent
                                on the specific model and the size of the dataset. We can see the progress of
                                training on the website, where you will see a progress bar of what task have been
                                done and an idea of how much has been done.
                            </p>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="List Models" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            List Models
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                To see all of your models you previously trained, use the following method to do so.
                            </p>
                            <p>
                                Do remember to add the Authorization Token which is essential.
                            </p>
                        </div>
                        <div class="col-12 pt-2">
                            <div class="col-12 p-3 code_section">
                                <div class="col-12 d-flex" style="justify-content: end; align-items: center;">
                                    <span class="desc me-3" style="color: #10A37F;" *ngIf="isCopied">Code Copied!</span>
                                    <button type="button" class="btn btn_copy"
                                        (click)="copyCodeToClipboard(codeListModels)">
                                        <span>
                                            <img width="16px" height="16px" src="../../../assets/copy-code-icon.svg">
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 desc ps-2" style="color: #FFFFFF;">
                                    <pre><code [innerHTML]="codeListModels"></code></pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="Response" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            Response
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                We will be providing a URL to use the API to get response from the fine-tuned model.
                                And we will provide you with an API-key which you will be using for authorization
                                purpose.
                            </p>
                            <p>
                                <span style="font-weight: bold;">Inputs:</span>
                                <span class="ms-1">New_Data_Api variable will be the input which will be sent
                                    through the API. In this we send user_query, object_key which is the model path
                                    that you have stores after training the base mode on your custom data and the
                                    api_key which we will provide. </span>
                            </p>
                        </div>
                        <div class="col-12 pt-2">
                            <div class="col-12 p-3 code_section">
                                <div class="col-12 d-flex" style="justify-content: end; align-items: center;">
                                    <span class="desc me-3" style="color: #10A37F;" *ngIf="isCopied">Code Copied!</span>
                                    <button type="button" class="btn btn_copy"
                                        (click)="copyCodeToClipboard(codeResponse)">
                                        <span>
                                            <img width="16px" height="16px" src="../../../assets/copy-code-icon.svg">
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 desc ps-2" style="color: #FFFFFF;">
                                    <pre><code [innerHTML]="codeResponse"></code></pre>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 desc pt-3">
                            <p>
                                <span style="font-weight: bold;">Outputs:</span>
                                <span class="ms-1">New_Data_Api variable will be the input which will be sent
                                    through the API. In this we send user_query, object_key which is the model path
                                    that you have stores after training the base mode on your custom data and the
                                    api_key which we will provide. </span>
                            </p>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="The DialectAI Advantage"
                        style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            The DialectAI Advantage
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Experience AI Like Never Before with DialectAI">
                            Experience AI Like Never Before with DialectAI
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                At DialectAI, we've completely transformed the world of AI customization, tapping into
                                the game-changing potential of auto fine-tuning. Our state-of-the-art platform draws
                                inspiration from the technology that powers AI advancements, building upon the
                                foundation set by pioneers like OpenAI. However, we've elevated it even more. With
                                DialectAI, the entire process has been automated, ensuring that crafting a personalized
                                AI model is now as simple as uploading a file. Let's dive into the remarkable benefits
                                that DialectAI brings to the table:
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Seamless Data Fusion with DialectAI">
                            Seamless Data Fusion with DialectAI
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                <span style="font-weight: bold;">Simplified Data Harmony:</span>
                                <span class="ms-1">
                                    DialectAI is meticulously crafted to harmonize effortlessly with an array of data
                                    formats. Whether you possess a series of PDFs, a plain text document, a CSV
                                    spreadsheet, or even a web link leading to a page, our platform adeptly captures
                                    pertinent details and transforms them into a purposeful training dataset.
                                </span>
                            </p>
                            <p>
                                <span style="font-weight: bold;">Automated Data Interpretation:</span>
                                <span class="ms-1">
                                    Bid farewell to the laborious task of manual data preparation. DialectAI's astute
                                    algorithms meticulously scrutinize your data, pinpoint patterns, and extract vital
                                    content to construct a resilient and all-encompassing training dataset.
                                </span>
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="The Power of AI Evolution">
                            The Power of AI Evolution
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                <span style="font-weight: bold;">Automated Refinement Wizardry:</span>
                                <span class="ms-1">
                                    The moment your data finds its way onto our platform, our automated fine-tuning
                                    wizardry takes center stage. Marrying the brilliance of DialectAI's technology with
                                    DialectAI's innovations, we orchestrate the metamorphosis of the foundational model,
                                    ensuring it resonates harmoniously with the intricate nuances of your data. The
                                    outcome? An AI model that converses in your very own language.
                                </span>
                            </p>
                            <p>
                                <span style="font-weight: bold;">Dynamic Evolutionary Process:</span>
                                <span class="ms-1">
                                    DialectAI isn't just a passive learner; it's a dynamic companion that evolves
                                    alongside your data. With every infusion of new information, your model undergoes a
                                    continuous process of refinement, honing its prowess to align ever more precisely
                                    with the distinct realm and context you operate within.
                                </span>
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Efficiency and Innovation">
                            Efficiency and Innovation
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                <span style="font-weight: bold;">Harmonious Fusion:</span>
                                <span class="ms-1">
                                    DialectAI's auto fine-tuned models meld seamlessly with DialectAI's Completions API,
                                    ushering in a frictionless encounter within your applications. This model is
                                    meticulously engineered to craft content attuned precisely to your requisites,
                                    enriching user engagements and fuelling the engines of innovation.
                                </span>
                            </p>
                            <p>
                                <span style="font-weight: bold;">Instant Empowerment:</span>
                                <span class="ms-1">
                                    The moment the auto fine-tuning ritual concludes, your tailor-made AI model stands
                                    prepared for action. No lingering, no interruptions – the might of AI rests at your
                                    very fingertips, conjuring content that strikes a chord with your audience.
                                </span>
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Empowering Your Vision">
                            Empowering Your Vision
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                Guided by a mission to amplify innovation, DialectAI stands as your partner in realizing
                                your vision. Our platform equips you with the means to shape your concepts into tangible
                                reality, to forge content that carries your distinct signature, and to redefine the
                                landscape of user interactions. With DialectAI, the intricate world of AI customization
                                ceases to be a labyrinthine journey; instead, it becomes a readily accessible avenue for
                                channeling your inventiveness and aspirations.
                            </p>
                        </div>
                        <div class="col-12 pt-3 sub_head_1" id="Embark on the AI Evolution">
                            Embark on the AI Evolution
                        </div>
                        <div class="col-12 desc pt-2">
                            <p>
                                Embark on a journey into the astounding frontiers of AI customization with DialectAI.
                                Our breakthrough auto fine-tuning technology empowers you to fashion your very own AI
                                model with unparalleled ease, unlocking the capability to produce content that resonates
                                intimately with your identity and aspirations. Together, let's forge the path to an
                                AI-infused future where the horizons of customization stretch endlessly and innovation
                                propels us forward. Your expedition into AI transformation finds its starting point in
                                DialectAI – a realm where auto fine-tuning transcends mere mechanics, and instead,
                                ushers you into a world of infinite prospects.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>