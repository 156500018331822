<div class="container-fluid">
    <div class="row">
        <div class="col-12 pt-4 pb-5 d-flex" style="justify-content: center;">
            <div class="col-md-11 col-12">
                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-12 head d-flex" style="justify-content: space-between;">
                        <span>{{ "Profile.Profile" | translate }}</span>
                        <span class="language dropdown-toggle me-3 d-none d-md-flex" id="dropdownLanguageSelect"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="text-transform: uppercase;">
                            {{ languageMode }}
                            <span>
                                <img class="dropdown-rotate" width="20px" height="20px"
                                    src="../../../assets/arrow-up-down.svg">
                            </span>
                        </span>
                        <div class="dropdown-menu dropdown-menu-right py-2" aria-labelledby="dropdownLanguageSelect">
                            <div class="col-12 dropdown-item" (click)="setLanguage('en')"
                                style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                English
                            </div>
                            <div class="col-12 dropdown-item" (click)="setLanguage('nl')"
                                style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                Dutch
                            </div>
                        </div>
                    </div>
                    <div class="col-12 form">
                        <div class="col-12 pt-5 d-flex"
                            style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                            <div class="col-md-6 col-12 d-flex" style="justify-content: center;">
                                <div class="col-12 d-flex" style="align-items: center;">
                                    <span class="circle d-flex" style="justify-content: center; align-items: center;">
                                        <img width="97px" height="97px" src="../../../assets/avatar-cartoon.png">
                                    </span>
                                    <span class="ms-4 desc">
                                        <p class="mb-0" style="font-weight: bold;">{{userName}}</p>
                                        <p>{{companyName}}</p>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-6 col-12 pt-md-0 pt-4 d-flex" style="justify-content: center;">
                                <div class="col-12 d-flex" style="align-items: center; justify-content: space-between;">
                                    <button type="button" class="btn p-2">{{ "Profile.Button.Upload" | translate
                                        }}</button>
                                    <button type="button" class="btn p-2"
                                        style="color: #202020; background-color: #FFFFFF; border: 1px solid #775da6;">
                                        {{ "Profile.Button.To delete" | translate }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-4 line"></div>
                        <div class="col-12 pt-4 ">
                            <form [formGroup]="formUserInfo" (ngSubmit)="onSubmitInfo()">
                                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12 head"
                                        style="font-size: calc(var(--font-large)*1.2); line-height: 36px; font-weight: bold; letter-spacing: 0px;">
                                        {{ "Profile.General" | translate }}
                                    </div>
                                    <div class="col-12 pt-3 d-flex"
                                        style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                                        <div class="col-md-6 col-12 d-flex" style="justify-content: start;">
                                            <div class="col-12 col-md-11">
                                                <div class="col-12 desc" style="font-weight: bold;">
                                                    {{ "Profile.User name" | translate }}
                                                </div>
                                                <div class="col-12 mt-2 d-flex"
                                                    style="position: relative; align-items: center;">
                                                    <img class="img" src="../../../assets/useName-icon.svg"
                                                        alt="userName">
                                                    <input matInput class="form-control" formControlName="Name"
                                                        name="Name" type="text"
                                                        placeholder="{{ 'Placeholder.Name' | translate }}">
                                                </div>
                                                <div class="col-12 d-flex" style="justify-content: start;">
                                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmittedUserInfo && errorControlUserInfo['Name']['errors']?.['required']">
                                                        {{ "SignUp.Name is required" | translate }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pt-md-0 pt-3 col-12 d-flex" style="justify-content: end;">
                                            <div class="col-12 col-md-11">
                                                <div class="col-12 desc" style="font-weight: bold;">
                                                    {{ "Profile.Company Name" | translate }}
                                                </div>
                                                <div class="col-12 mt-2 d-flex"
                                                    style="position: relative; align-items: center;">
                                                    <img class="img" src="../../../assets/company-icon.svg"
                                                        alt="Company">
                                                    <input matInput class="form-control" formControlName="Company"
                                                        name="Company" type="text"
                                                        placeholder="{{ 'Placeholder.Company' | translate }}">
                                                </div>
                                                <div class="col-12 d-flex" style="justify-content: start;">
                                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmittedUserInfo && errorControlUserInfo['Company']['errors']?.['required']">
                                                        {{ "SignUp.Company is required" | translate }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 pt-3 desc" style="font-weight: bold;">
                                        {{ "Profile.E-mail address" | translate }}
                                    </div>
                                    <div class="col-12 mt-2 d-flex"
                                        style="position: relative; justify-content: start; align-items: center;">
                                        <img class="img" src="../../../assets/email-icon.svg" alt="Email">
                                        <input matInput class="form-control" formControlName="Email" name="Email"
                                            type="email" placeholder="{{ 'Placeholder.Username or email' | translate }}"
                                            readonly>
                                    </div>
                                    <div class="col-12 pt-4 mt-md-1 d-flex" style="justify-content: end;">
                                        <button type="submit" class="btn p-2 d-flex" style="justify-content: center;"
                                            [disabled]="idLoadingUserInfo">
                                            <span *ngIf="!idLoadingUserInfo">
                                                {{ "Profile.Button.Update" | translate }}
                                            </span>
                                            <span *ngIf="idLoadingUserInfo">
                                                <mat-spinner diameter="25" strokeWidth="4"></mat-spinner>
                                            </span>
                                        </button>
                                    </div>
                                    <!-- <div class="col-12 pt-4">
                                        <div class="col-12 mt-2 line"></div>
                                    </div>
                                    <div class="col-12 pt-4 head"
                                        style="font-size: calc(var(--font-large)*1.2); line-height: 36px; font-weight: bold; letter-spacing: 0px;">
                                        {{ "Profile.Account management" | translate }}
                                    </div>
                                    <div class="col-12 pt-4">
                                        <div class="col-12 desc">
                                            {{ "Profile.Are you sure you want to delete your account?" | translate }}
                                        </div>
                                        <div class="col-12 pt-3 d-flex" style="justify-content: end;">
                                            <button type="button" class="btn p-2"
                                                style="color: #202020; background-color: #FFFFFF; border: 1px solid #775da6;">
                                                {{ "Profile.Button.To delete" | translate }}</button>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-12 pt-4 mt-md-1 d-flex"
                                        style="justify-content: space-between; flex-wrap: wrap;">
                                        <div class="col-md-6 col-12 d-flex"
                                            style="justify-content: start; flex-wrap: wrap;">
                                            <div class="col-md-11 col-12">
                                                <div class="col-12 desc">
                                                    Weet u zeker dat u uw abonnement wilt opzeggen?
                                                </div>
                                                <div class="col-12 pt-3 d-flex" style="justify-content: end;">
                                                    <button type="button" class="btn p-2"
                                                        style="color: #202020; background-color: #FFFFFF; border: 1px solid #775da6;">Uitschrijven</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-12 pt-md-0 pt-4 d-flex"
                                            style="justify-content: end; flex-wrap: wrap;">
                                            <div class="col-md-11 col-12">
                                                <div class="col-12 desc">
                                                    Weet je zeker dat je je account wilt verwijderen?
                                                </div>
                                                <div class="col-12 pt-3 d-flex" style="justify-content: end;">
                                                    <button type="button" class="btn p-2"
                                                        style="color: #202020; background-color: #FFFFFF; border: 1px solid #775da6;">Verwijderen</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </form>
                        </div>
                        <div class="col-12 pt-4">
                            <div class="col-12 mt-2 line"></div>
                        </div>
                        <div class="col-12 pt-4 ">
                            <form [formGroup]="formUserPass" (ngSubmit)="onSubmitPass()">
                                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12 head"
                                        style="font-size: calc(var(--font-large)*1.2); line-height: 36px; font-weight: bold; letter-spacing: 0px;">
                                        {{ "Profile.Security" | translate }}
                                    </div>
                                    <div class="col-12 pt-3 d-flex"
                                        style="justify-content: space-between; align-items: start; flex-wrap: wrap;">
                                        <div class="col-md-6 col-12 d-flex" style="justify-content: start;">
                                            <div class="col-12 col-md-11">
                                                <div class="col-12 desc" style="font-weight: bold;">
                                                    {{ "Profile.Current Password" | translate }}
                                                </div>
                                                <div class="col-12 mt-2 d-flex"
                                                    style="position: relative; align-items: center;">
                                                    <img class="img" src="../../../assets/password-icon.svg"
                                                        alt="password-icon">
                                                    <input matInput class="form-control" formControlName="PasswordOld"
                                                        name="PasswordOld"
                                                        [type]="showPasswordOld ? 'password' : 'text'"
                                                        placeholder="{{ 'Placeholder.Current Password' | translate }}"
                                                        style="padding-right: 40px;">
                                                    <img (click)="togglePasswordOld()" class="eye"
                                                        [src]="showPasswordOld ? '../../assets/eye-icon.svg' : '../../assets/eye-slash.svg'"
                                                        alt="Eye Icon">
                                                </div>
                                                <div class="col-12 d-flex" style="justify-content: start;">
                                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmittedUserPass && errorControlUserPass['PasswordOld']['errors']?.['required']">
                                                        {{ "SignUp.Password is required.a" | translate }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 pt-md-0 pt-3 col-12 d-flex" style="justify-content: end;">
                                            <div class="col-12 col-md-11">
                                                <div class="col-12 desc" style="font-weight: bold;">
                                                    {{ "Profile.New password" | translate }}
                                                </div>
                                                <div class="col-12 mt-2 d-flex"
                                                    style="position: relative; align-items: center;">
                                                    <img class="img" src="../../../assets/password-icon.svg"
                                                        alt="password-icon">
                                                    <input matInput class="form-control" formControlName="Password"
                                                        name="Password" [type]="showPasswordNew ? 'password' : 'text'"
                                                        placeholder="{{ 'Placeholder.New Password' | translate }}"
                                                        style="padding-right: 40px;">
                                                    <img (click)="togglePasswordNew()" class="eye"
                                                        [src]="showPasswordNew ? '../../assets/eye-icon.svg' : '../../assets/eye-slash.svg'"
                                                        alt="Eye Icon">
                                                </div>
                                                <div class="col-12 d-flex" style="justify-content: start;">
                                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmittedUserPass && errorControlUserPass['Password']['errors']?.['required']">
                                                        {{ "SignUp.Password is required.a" | translate }}
                                                    </mat-error>
                                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmittedUserPass && errorControlUserPass['Password']['errors']?.['pattern']">
                                                        {{ "SignUp.Password is required.b" | translate }}
                                                    </mat-error>
                                                    <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmittedUserPass && errorControlUserPass['Password']['errors']?.['minlength'] && !errorControlUserPass['Password']['errors']?.['pattern']">
                                                        {{ "SignUp.Password is required.c" | translate }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 pt-3 desc" style="font-weight: bold;">
                                        {{ "Profile.Confirm new Password" | translate }}
                                    </div>
                                    <div class="col-12 mt-2 d-flex"
                                        style="position: relative; justify-content: start; align-items: center;">
                                        <img class="img" src="../../../assets/password-icon.svg" alt="password-icon">
                                        <input matInput class="form-control" formControlName="ConfirmPassword"
                                            name="ConfirmPassword" [type]="showPasswordConfirm ? 'password' : 'text'"
                                            placeholder="{{ 'Placeholder.Confirm new Password' | translate }}"
                                            style="padding-right: 40px;">
                                        <img (click)="togglePasswordConfirm()" class="eye"
                                            [src]="showPasswordConfirm ? '../../assets/eye-icon.svg' : '../../assets/eye-slash.svg'"
                                            alt="Eye Icon">
                                    </div>
                                    <div class="col-12 d-flex" style="justify-content: start;">
                                        <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                            *ngIf="isSubmittedUserPass && errorControlUserPass['ConfirmPassword']['errors']?.['required']">
                                            {{ "SignUp.Confirm Password is required" | translate }}
                                        </mat-error>
                                        <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                            *ngIf="isSubmittedUserPass && formUserPass.hasError('passwordMismatch')">
                                            {{ errorMessage }}
                                        </mat-error>
                                    </div>
                                    <div class="col-12 pt-4 mt-md-1 d-flex" style="justify-content: end;">
                                        <button type="submit" class="btn p-2 d-flex" style="justify-content: center;"
                                            [disabled]="idLoadingUserPass">
                                            <span *ngIf="!idLoadingUserPass">
                                                {{ "Profile.Button.Update" | translate }}
                                            </span>
                                            <span *ngIf="idLoadingUserPass">
                                                <mat-spinner diameter="25" strokeWidth="4"></mat-spinner>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-12 pt-4">
                            <div class="col-12 mt-2 line"></div>
                        </div>
                        <div class="col-12 pt-4 head"
                            style="font-size: calc(var(--font-large)*1.2); line-height: 36px; font-weight: bold; letter-spacing: 0px;">
                            {{ "Profile.Subscription management" | translate }}
                        </div>
                        <div class="col-12 pt-4">
                            <div class="col-12 desc">
                                {{ "Profile.Are you sure you want to cancel your subscription?" | translate
                                }}
                            </div>
                            <div class="col-12 pt-3 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                <div class="col-md-6"></div>
                                <div class="col-md-6 col-12 d-flex" style="justify-content: center;">
                                    <div class="col-12 d-flex"
                                        style="align-items: center; justify-content: space-between;">
                                        <button type="button" class="btn p-2"
                                            style="color: #202020; background-color: #FFFFFF; border: 1px solid #775da6;"
                                            (click)="goBack()">
                                            {{ "Profile.Button.Cancel" | translate }}</button>
                                        <button type="button" class="btn p-2 d-flex" style="justify-content: center;"
                                            [disabled]="isLoadingTrialEnd" (click)="manageSubscription()">
                                            <span *ngIf="!isLoadingTrialEnd">
                                                {{ "Profile.Button.Unsubscribe" | translate }}
                                            </span>
                                            <span *ngIf="isLoadingTrialEnd">
                                                <mat-spinner diameter="25" strokeWidth="4"></mat-spinner>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-12 d-flex" style="align-items: end; flex: 1;">
            <div class="col-12 footer" style="flex: 1;">
                <app-footer></app-footer>
            </div>
        </div> -->
    </div>
</div>