<div class="container-fluid">
    <div class="row">
        <div class="col-12 pt-3 d-flex" style="justify-content: space-between; align-items: start;">
            <img src="../../../assets/Logo-1-dark.png" width="100px" height="50px" alt="Logo-1-dark">
            <img src="../../../assets/close-btn.svg" (click)="closeForm()" style="cursor: pointer;">
        </div>
    </div>
    <div class="row">
        <div class="col-12 p-5 pt-2 pt-md-4  mt-1 d-flex"
            style="justify-content: center; align-items: center; flex-wrap: wrap;">
            <!-- <div class="col-12 d-flex" style="justify-content: end;">
                <img src="../../../assets/Logo-1-dark.png" width="100px" height="50px" alt="Logo-1-dark">
            </div> -->
            <div class="col-12 col-md-10 d-flex" style="justify-content: center; align-items: center; flex-wrap: wrap;">
                <div class="col-12 pb-3 head" style="font-size: calc(var(--font-medium)*1.25); line-height: 24px;">
                    {{ "Invalid files.Invalid files:" | translate }}
                </div>
                <div class="col-12 pt-3 desc" *ngFor="let file of data">
                    <div class="col-12 p-3"
                        style="font-weight: bold; border-radius: 10px; border: 0.24000002441406498px solid #dcdbdd;">
                        <div class="col-12">
                            {{ file.id }}. <span class="ps-1" style="font-weight: 500;">{{ file.data_key }}</span>
                        </div>
                        <div class="col-12 pt-1">
                            <span style="font-weight: 500;">{{ file.message }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-5">
                    <button type="submit" (click)="checkFilesAgain()" class="btn p-2 ps-5 pe-5 form-btn">
                        {{ "Invalid files.Edit files" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>