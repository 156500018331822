import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './AuthService';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
    const token = localStorage.getItem('token');
    if (token) {
      return this.authService.isAuthenticated().pipe(
        tap((authenticated) => {
          if (authenticated) {
            const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
            const isSignedUp = localStorage.getItem('isSignedUp') === 'true';
            const isModelMake = localStorage.getItem('isModelMake') === 'true';
            const isChooseSubscription = localStorage.getItem('isChooseSubscription') === 'true';
            if (isLoggedIn) {
              this.router.navigate(['/sidebar']);
            }
            else if (isSignedUp) {
              this.router.navigate(['/onboarding']);
            } else if (isModelMake) {
              this.router.navigate(['/start-training']);
            } else if (isChooseSubscription) {
              this.router.navigate(['/choose-subscription']);
            }
          } else {
            // Clear localStorage when the token is not valid
            localStorage.clear();
            this.router.navigate(['/sign-in']);
          }
        }),
        map((authenticated: any) => !authenticated),
        catchError(() => {
          // Clear localStorage on error
          localStorage.clear();
          this.router.navigate(['/sign-in']);
          return of(false);
        })
      );
    } else { return of(true); }
  }

}