<nav class="navbar navbar-expand-lg sticky-top">
    <div class="container-fluid">
        <div class="col-12 p-1 d-flex" style="justify-content: space-between; flex-wrap: wrap;">
            <a class="navbar-brand">
                <img src="../../../assets/Logo-1-dark.png" width="80px" height="40px" alt="Logo-1-dark"
                    routerLink="/sidebar">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <div class="col-12 d-flex" style="justify-content: space-between; align-items: center;">
                    <ul class="navbar-nav">
                        <!-- <li class="nav-item">
                            <a class="nav-link" (click)="showSection('Overview')"
                                [class.active-link]="activeSection === 'Overview'">Overview</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" (click)="showSection('Documentation')"
                                [class.active-link]="activeSection === 'Documentation'">Documentation</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" (click)="showSection('APIReference')"
                                [class.active-link]="activeSection === 'APIReference'">
                                {{ "Docs.API Reference.API Reference" | translate }}</a>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item d-flex name-text" style="justify-content: end; align-items: center;">
                            <span class="language dropdown-toggle me-3" id="dropdownLanguageSelect"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                style="text-transform: uppercase;">
                                {{ languageMode }}
                                <span>
                                    <img class="dropdown-rotate" width="20px" height="20px"
                                        src="../../../assets/arrow-up-down.svg">
                                </span>
                            </span>
                            <div class="dropdown-menu py-2" aria-labelledby="dropdownLanguageSelect">
                                <div class="col-12 dropdown-item" (click)="setLanguage('en')"
                                    style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                    English
                                </div>
                                <div class="col-12 dropdown-item" (click)="setLanguage('nl')"
                                    style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                    Dutch
                                </div>
                            </div>
                            <p class="pe-3 mb-0">{{userName}} <br>
                                <span style="font-size: calc(var(--font-small)); color: #888a91;">{{companyName}}</span>
                            </p>
                            <div class="circle">
                                <img class="profile_img" src="../../../assets/avatar-cartoon.png">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</nav>
<div class="container-fluid">
    <div class="row" [ngSwitch]="activeSection">
        <ng-container *ngSwitchCase="'Documentation'">
            <app-documentation></app-documentation>
        </ng-container>
        <ng-container *ngSwitchCase="'APIReference'">
            <app-api-reference></app-api-reference>
        </ng-container>
        <!-- <ng-container *ngSwitchCase="'Overview'">
            <app-overview></app-overview>
        </ng-container> -->
    </div>
</div>

<!-- <div class="col-12 d-flex" style="justify-content: center; align-items: center;">
    <div class="wrapper">
        <div class="sidebar d-lg-flex d-none">
            <div class="col-12 p-1 pt-3 pb-3" style="height: 100%; overflow-y: auto;">
                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-12" *ngIf="activeSection === 'Overview'">
                        Overview
                    </div>
                    <div class="col-11 d-flex" *ngIf="activeSection === 'Documentation'"
                        style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 mt-3 desc" style="font-weight: 600;">
                            GET STARTED
                        </div>
                        <div class="col-12" *ngFor="let item of documentation; let i = index">
                            <div class="col-12 mt-3 desc" style="cursor: pointer;">
                                {{item.name}}
                            </div>
                            <ng-container *ngIf="(i + 1) % 7 === 0 && item.id === '07'">
                                <div class="col-12 mt-4 pt-1 pb-1 desc" style="font-weight: 600;">
                                    GUIDES
                                </div>
                            </ng-container>
                            <ng-container *ngIf="(i + 1) % 18 === 0 && item.id === '18'">
                                <div class="col-12 mt-4 pt-1 pb-1 desc" style="font-weight: 600;">
                                    GHAT PLUGINS
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="activeSection === 'APIReference'">
                        API Reference
                    </div>
                </div>
            </div>
        </div>
        <div class="main_container">
            <div class="col-12 d-flex" style="justify-content: center; height: 100%; overflow-y: auto;">
                <div class="col-md-11 col-12 d-flex" style="justify-content: center;">
                    <div class="col-12 pt-3 d-flex" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12" *ngIf="activeSection === 'Overview'">
                            This is a Overview part
                        </div>
                        <div class="col-12" *ngIf="activeSection === 'Documentation'">
                            This is a Documentation part
                        </div>
                        <div class="col-12" *ngIf="activeSection === 'APIReference'">
                            This is a API Reference part
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->