<div class="col-12 d-flex" style="justify-content: center; align-items: center;">
    <div class="wrapper">
        <div class="sidebar">
            <div class="col-12 p-1 pt-3 pb-3" style="height: calc(100% - 75px); overflow-y: auto;">
                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-11 d-flex" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 mt-3 desc" style="font-weight: 600;">
                            {{ "Docs.API Reference.API REFERENCE" | translate }}
                        </div>
                        <div class="col-12" *ngFor="let item of documentation; let i = index">
                            <div class="col-12 mt-3 nav-link" (click)="toggleSubItems(item.name)"
                                [class.active]="selectedSectionName === item.name && selectedSubItems === ''">
                                {{ "Docs.API Reference." + item.name | translate }}
                            </div>
                            <!-- <div class="col-12 d-flex" *ngIf="selectedSectionName === item.name"
                                style="justify-content: end; flex-wrap: wrap;">
                                <div class="col-11 mt-2 nav-link" *ngFor="let subItem of item.subItems"
                                    (click)="scrollToSection(subItem)" [class.active]="selectedSubItems === subItem">
                                    {{subItem}}
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main_container">
            <div class="col-12 pt-4 pb-4 d-flex" style="justify-content: center; height: 100%; overflow-y: auto;">
                <div class="col-md-11 col-xl-10 col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-12 d-flex" style="justify-content: end;">
                        <button type="submit" class="btn btn_dash p-2 px-4 btn d-flex" routerLink="/sidebar"
                            style="justify-content: center; align-items: center;">
                            {{ "Docs.API Reference.Go To Dashboard" | translate }}</button>
                    </div>
                    <!-- <div class="col-12 pt-2 d-flex" id="List Models" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            List Models
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                To see all of your models you previously trained, use the following method to do so.
                            </p>
                            <p>
                                Do remember to add the Authorization Token which is essential.
                            </p>
                        </div>
                        <div class="col-12 pt-2">
                            <div class="col-12 p-3 code_section">
                                <div class="col-12 d-flex" style="justify-content: end; align-items: center;">
                                    <span class="desc me-3" style="color: #10A37F;" *ngIf="isCopied">Code Copied!</span>
                                    <button type="button" class="btn btn_copy"
                                        (click)="copyCodeToClipboard(codeListModels)">
                                        <span>
                                            <img width="16px" height="16px" src="../../../assets/copy-code-icon.svg">
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 desc ps-2" style="color: #FFFFFF;">
                                    <pre><code [innerHTML]="codeListModels"></code></pre>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-12 pt-4 d-flex" id="Response" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            Response
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                We will be providing a URL to use the API to get response from the fine-tuned model.
                                And we will provide you with an API-key which you will be using for authorization
                                purpose.
                            </p>
                            <p>
                                <span style="font-weight: bold;">Inputs:</span>
                                <span class="ms-1">New_Data_Api variable will be the input which will be sent
                                    through the API. In this we send user_query, object_key which is the model path
                                    that you have stores after training the base mode on your custom data and the
                                    api_key which we will provide. </span>
                            </p>
                        </div>
                        <div class="col-12 pt-2">
                            <div class="col-12 p-3 code_section">
                                <div class="col-12 d-flex" style="justify-content: end; align-items: center;">
                                    <span class="desc me-3" style="color: #10A37F;" *ngIf="isCopied">Code Copied!</span>
                                    <button type="button" class="btn btn_copy"
                                        (click)="copyCodeToClipboard(codeResponse)">
                                        <span>
                                            <img width="16px" height="16px" src="../../../assets/copy-code-icon.svg">
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 desc ps-2" style="color: #FFFFFF;">
                                    <pre><code [innerHTML]="codeResponse"></code></pre>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 desc pt-3">
                            <p>
                                <span style="font-weight: bold;">Outputs:</span>
                                <span class="ms-1">New_Data_Api variable will be the input which will be sent
                                    through the API. In this we send user_query, object_key which is the model path
                                    that you have stores after training the base mode on your custom data and the
                                    api_key which we will provide. </span>
                            </p>
                        </div>
                    </div> -->
                    <div class="col-12 pt-4 d-flex" id="Get Response" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            {{ "Docs.API Reference.Get Response" | translate }}
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                {{ "Docs.API Reference.To generate the response..." | translate }}
                            </p>
                        </div>
                        <div class="col-12 pt-2">
                            <div class="col-12 p-3 code_section">
                                <div class="col-12 d-flex" style="justify-content: end; align-items: center;">
                                    <!-- <span class="desc me-3" style="color: #10A37F;"
                                        *ngIf="isCopied && copiedCodeSection === 'codeResponse'">Code Copied!</span> -->
                                    <button type="button" class="btn btn_copy dropdown-toggle me-4"
                                        id="dropdownLanguageSelect" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <span class="me-1">{{ codeResponse[indexResponse].name }}</span>
                                        <span>
                                            <img class="dropdown-rotate" width="20px" height="20px"
                                                src="../../../assets/arrow-down.svg">
                                        </span>
                                    </button>
                                    <div class="dropdown-menu py-2" aria-labelledby="dropdownLanguageSelect">
                                        <div class="col-12 dropdown-item" (click)="setActiveResponse(0)"
                                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                            Curl
                                        </div>
                                        <div class="col-12 dropdown-item" (click)="setActiveResponse(1)"
                                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                            Node.js
                                        </div>
                                        <div class="col-12 dropdown-item" (click)="setActiveResponse(2)"
                                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                            Python
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn_copy"
                                        (click)="copyCodeToClipboard(codeResponse[indexResponse].code, 'codeResponse')">
                                        <span>
                                            <img width="16px" height="16px" src="../../../assets/copy-code-icon.svg">
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 desc ps-2" style="color: #FFFFFF;">
                                    <pre><code [innerHTML]="codeResponse[indexResponse].code"></code></pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="Get Conversation"
                        style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            {{ "Docs.API Reference.Get Conversation" | translate }}
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                {{ "Docs.API Reference.To retrieve the conversation history..." | translate }}
                            </p>
                        </div>
                        <div class="col-12 pt-2">
                            <div class="col-12 p-3 code_section">
                                <div class="col-12 d-flex" style="justify-content: end; align-items: center;">
                                    <!-- <span class="desc me-3" style="color: #10A37F;"
                                        *ngIf="isCopied && copiedCodeSection === 'getConversation'">Code Copied!</span> -->
                                    <button type="button" class="btn btn_copy dropdown-toggle me-4"
                                        id="dropdownLanguageSelect" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <span class="me-1">{{ getConversation[indexConversation].name }}</span>
                                        <span>
                                            <img class="dropdown-rotate" width="20px" height="20px"
                                                src="../../../assets/arrow-down.svg">
                                        </span>
                                    </button>
                                    <div class="dropdown-menu py-2" aria-labelledby="dropdownLanguageSelect">
                                        <div class="col-12 dropdown-item" (click)="setActiveConversation(0)"
                                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                            Curl
                                        </div>
                                        <div class="col-12 dropdown-item" (click)="setActiveConversation(1)"
                                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                            Node.js
                                        </div>
                                        <div class="col-12 dropdown-item" (click)="setActiveConversation(2)"
                                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                                            Python
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn_copy"
                                        (click)="copyCodeToClipboard(getConversation[indexConversation].code, 'getConversation')">
                                        <span>
                                            <img width="16px" height="16px" src="../../../assets/copy-code-icon.svg">
                                        </span>
                                    </button>
                                </div>
                                <div class="col-12 desc ps-2" style="color: #FFFFFF;">
                                    <pre><code [innerHTML]="getConversation[indexConversation].code"></code></pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex" id="Get Conversation"
                        style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            {{ "Docs.API Reference.Get Postman Collection" | translate }}
                        </div>
                        <div class="col-12 desc pt-4">
                            <p>
                                <span>{{ "Docs.API Reference.To obtain the Postman collection..." | translate }}</span>
                                <span class="ms-1" style="color: #775da6; font-style: italic; cursor: pointer;"
                                    (click)="downloadPostmanCollection()">
                                    {{ "Docs.API Reference.Download" | translate }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>