export const documentation = [
  {
    id: '01',
    name: 'Introduction',
    subItems: ['Overview', 'Key concepts', 'Next steps'],
  },
  {
    id: '02',
    name: 'Quick Start',
    subItems: [
      'Introduction',
      'Start with an instruction',
      'Add some examples',
      'Adjust your settings',
      'Build your application',
      'Pricing',
      'Closing',
      'Next steps',
    ],
  },
  {
    id: '03',
    name: 'Libraries',
    subItems: [
      'Python library',
      'Node.js library',
      'Azure OpenAI libraries',
      'Community libraries',
    ],
  },
  {
    id: '04',
    name: 'Models',
    subItems: ['GPT-4', 'GPT-3.5', 'DALL·E', 'Whisper'],
  },
  { id: '05', name: 'Deprecations' },
  { id: '06', name: 'Toturials' },
  { id: '07', name: 'Policies' },
  { id: '08', name: 'GPT' },
  { id: '09', name: 'GPT best practices' },
  { id: '10', name: 'Image generation' },
  { id: '11', name: 'Fine-tunning' },
  { id: '12', name: 'Embeddings' },
  { id: '13', name: 'Speech to text' },
  { id: '14', name: 'Moderation' },
  { id: '15', name: 'Rate limits' },
  { id: '16', name: 'Error codes' },
  { id: '17', name: 'Safety best practices' },
  { id: '18', name: 'Production best practices' },
  { id: '19', name: 'Introduction' },
  { id: '20', name: 'Getting started' },
  { id: '21', name: 'Authentication' },
  { id: '22', name: 'Examples' },
  { id: '23', name: 'Production' },
  { id: '24', name: 'Plugin review' },
  { id: '25', name: 'Plugin policies' },
];

export const documentation1 = [
  { id: '01', name: 'Introduction' },
  { id: '02', name: 'Dialect Model' },
  { id: '03', name: 'Pricing' },
  { id: '04', name: 'Closing' },
  {
    id: '05',
    name: 'Fine Tuning',
    subItems: [
      'The Power of Auto Fine-Tuning',
      'Revolutionizing the Path from Data to Distinct Model: Unveiling the Journey',
      'Smooth Incorporation and Application',
      "Revolutionizing Customization: DialectAI's Vision",
      'Initiating Your AI Voyage: Step into the Future with DialectAI',
      'Preparing Data',
      'Creating the Model',
      'During Model Training',
    ],
  },
  { id: '06', name: 'List Models' },
  { id: '07', name: 'Response' },
  {
    id: '08',
    name: 'The DialectAI Advantage',
    subItems: [
      'Experience AI Like Never Before with DialectAI',
      'Seamless Data Fusion with DialectAI',
      'The Power of AI Evolution',
      'Efficiency and Innovation',
      'Empowering Your Vision',
      'Embark on the AI Evolution',
    ],
  },
];

export const codeResponse = [
  {
    id: 0,
    name: 'Curl',
    code: `curl --location 'https://api.dialect-ai.com/response' 
    --header 'Content-Type: application/json' 
    --data-raw '{
        "user_query" : [
                    {
                      "role": "ASSISTANT",
                      "message":"hello"
                    }
                ], 
        "api_key" : "your_api_key",
        "email" : "your_email",          //optional
        "session_id" : "your_session_id"          //optional
    }'`,
  },
  {
    id: 1,
    name: 'Node.js',
    code: `const axios = require('axios');
    let data = JSON.stringify({
      "user_query": [
        {
          "role": "ASSISTANT",
          "message": "hello"
        }
      ],
      "api_key": "DA_XXXXXXXXXXXXXXXXXXXXXXXX",
      "email": "xyz@domain.com",          //optional
      "session_id": "xxxxxxxxxxxx"          //optional
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.dialect-ai.com/response',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
    `,
  },
  {
    id: 2,
    name: 'Python',
    code: `import requests
    import json
    
    url = "https://api.dialect-ai.com/response"
    
    payload = json.dumps({
      "user_query": [
        {
          "role": "ASSISTANT",
          "message": "hello"
        }
      ],
      "api_key": "DA_XXXXXXXXXXXXXXXXXXXXXXXX",
      "email": "xyz@domain.com",          //optional
      "session_id": "xxxxxxxxxxxx"          //optional
    })
    headers = {
      'Content-Type': 'application/json'
    }
    
    response = requests.request("POST", url, headers=headers, data=payload)
    
    print(response.text)
    `,
  },
];

export const codeListModels = `curl location --request POST 'http://
      dialectai-usersmodule-env.eba-snbdy89c.eu-west-1.
      elasticbeanstalk.com/api/usermodels'
  --header 'Authorization: Bearer <token>'`;

export const getConversation = [
  {
    id: 0,
    name: 'Curl',
    code: `curl --location 'https://api.dialect-ai.com/gethistory' 
    --header 'Content-Type: application/json' 
    --data-raw '{
        "api_key" : "your_api_key",
        "session_id" : "your_session_id",          //optional
        "email" : "your_email"          //optional
    }'`,
  },
  {
    id: 1,
    name: 'Node.js',
    code: `const axios = require('axios');
    let data = JSON.stringify({
      "api_key": "DA_XXXXXXXXXXXXXXXXXXXXXXXX",
      "session_id": "xxxxxxxxxxxx",          //optional
      "email": "xyz@domain.com"          //optional
    });
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.dialect-ai.com/gethistory',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
    `,
  },
  {
    id: 0,
    name: 'Python',
    code: `import requests
    import json
    
    url = "https://api.dialect-ai.com/gethistory"
    
    payload = json.dumps({
      "api_key": "DA_XXXXXXXXXXXXXXXXXXXXXXXX",
      "session_id": "xxxxxxxxxxxx",          //optional
      "email": "xyz@domain.com"          //optional
    })
    headers = {
      'Content-Type': 'application/json'
    }
    
    response = requests.request("POST", url, headers=headers, data=payload)
    
    print(response.text)
    `,
  },
];
