import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { WelcomePageComponent } from './component/welcome-page/welcome-page.component';
// import { AiAgentComponent } from './component/ai-agent/ai-agent.component';
// import { FinalCheckComponent } from './component/final-check/final-check.component';
import { StartTrainingComponent } from './component/start-training/start-training.component';
// import { HomeComponent } from './component/home/home.component';
// import { SidebarComponent } from './component/sidebar/sidebar.component';
// import { AgentsComponent } from './component/agents/agents.component';
// import { FinancieelComponent } from './component/financieel/financieel.component';
// import { FinancieelDetailComponent } from './component/financieel-detail/financieel-detail.component';
// import { ChatComponent } from './component/chat/chat.component';
import { SignInComponent } from './form/sign-in/sign-in.component';
import { SignUpComponent } from './form/sign-up/sign-up.component';
import { Signup2TrailComponent } from './component/signup2-trail/signup2-trail.component';

import { AuthGuard } from './auth.guard';
// import { DashboardGuard } from './authGuard/dashboard.guard';
// import { OnboardingGuard } from './authGuard/onboarding.guard';
import { SignupGuard } from './authGuard/signup.guard';
import { startTrainingGuard } from './authGuard/start-training.guard';
import { Signup3SubscriptionComponent } from './component/signup3-subscription/signup3-subscription.component';
import { SignupPaymentGuard } from './authGuard/signup-payment.guard';
import { DocsComponent } from './component/docs/docs.component';
import { DocumentationComponent } from './component/documentation/documentation.component';
import { ApiReferenceComponent } from './component/api-reference/api-reference.component';
import { OverviewComponent } from './component/overview/overview.component';
import { ProfileComponent } from './component/profile/profile.component';
import { HelpCenterComponent } from './component/help-center/help-center.component';
import { HelpCenterQuestionComponent } from './component/help-center/help-center-question/help-center-question.component';
import { HelpCenterAnswerComponent } from './component/help-center/help-center-answer/help-center-answer.component';

const routes: Routes = [
  { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent, canActivate: [AuthGuard] },
  { path: 'sign-up', component: SignUpComponent, canActivate: [SignupGuard] },
  // {
  //   path: 'onboarding', component: WelcomePageComponent, canActivate: [OnboardingGuard], children: [
  //     { path: 'ai-agent', component: AiAgentComponent },
  //     { path: 'final-check', component: FinalCheckComponent },
  //   ]
  // },
  {
    path: 'onboarding',
    loadChildren: () => import('./Module/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  { path: 'start-training', component: StartTrainingComponent, canActivate: [startTrainingGuard] },
  // {
  //   path: 'sidebar', component: SidebarComponent, canActivate: [DashboardGuard], children: [
  //     { path: 'home', component: HomeComponent },
  //     { path: 'agents', component: AgentsComponent },
  //     { path: 'financieel', component: FinancieelComponent },
  //     { path: 'financieel-detail', component: FinancieelDetailComponent },
  //     { path: 'chat', component: ChatComponent },
  //   ]
  // },
  {
    path: 'sidebar',
    loadChildren: () => import('./Module/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'choose-subscription', component: Signup2TrailComponent, canActivate: [SignupPaymentGuard], children: [
      { path: 'regular-subscription', component: Signup3SubscriptionComponent },
    ]
  },
  {
    path: 'docs', component: DocsComponent, children: [
      { path: 'documentation', component: DocumentationComponent },
      { path: 'api-reference', component: ApiReferenceComponent },
      { path: 'overview', component: OverviewComponent },
    ]
  },
  { path: 'help-center', component: HelpCenterComponent },
  { path: 'help-center-question/:id', component: HelpCenterQuestionComponent },
  { path: 'help-center-answer/:section', component: HelpCenterAnswerComponent },
  { path: 'profile', component: ProfileComponent },
  { path: '**', redirectTo: 'sign-in' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
