import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';
import { uploadFileService } from 'src/app/services/uploadFileService';

@Component({
  selector: 'app-data-upload-url',
  templateUrl: './data-upload-url.component.html',
  styleUrls: ['./data-upload-url.component.scss']
})
export class DataUploadUrlComponent implements OnInit {
  form: FormGroup;
  isSubmitted = false;
  uploadedUrls: string[] = [];
  urlInputs: FormControl[] = [];

  invalidFiles: any[] = [];
  errorMessage: string = '';

  constructor(public dialogRef: MatDialogRef<DataUploadUrlComponent>, private formBuilder: FormBuilder, private uploadFileService: uploadFileService, private translate: TranslateService, private languageService: LanguageService) {
    this.translate.setDefaultLang(this.languageService.getLanguage());

    this.form = this.formBuilder.group({
      URL: ['']
    });
  }

  ngOnInit(): void {
    // Retrieve the previously uploaded URLs from the data service
    this.uploadedUrls = this.uploadFileService.getUploadedUrls();
    this.urlInputs = this.uploadedUrls.map(url => new FormControl(url, [Validators.required]));

    if (this.urlInputs.length === 0) {
      this.addURLInput();
    }

    //Retrive invalid files if exist
    this.invalidFiles = this.uploadFileService.getInvalidFiles();

    // Listen for changes in each URL input control
    this.urlInputs.forEach((inputControl, index) => {
      inputControl.valueChanges.subscribe((newValue) => {
        const removedUrl = this.uploadedUrls[index];

        // Remove the existing file from invalidFiles if it exists
        if (removedUrl) {
          this.invalidFiles = this.invalidFiles.filter(file => file.data_key !== removedUrl);
          this.uploadFileService.saveInvalidFiles(this.invalidFiles);
        }
      });
    });
  }

  get errorControl() {
    return this.form.controls;
  }

  addURLInput() {
    this.urlInputs.push(new FormControl('', [Validators.required]));
  }

  removeURLInput(index: number) {
    const removedUrl = this.urlInputs[index].value; // Store the URL value
    this.urlInputs.splice(index, 1);

    // Remove the existing file from invalidFiles if it exists
    if (removedUrl) {
      this.invalidFiles = this.invalidFiles.filter(file => file.data_key !== removedUrl);
      this.uploadFileService.saveInvalidFiles(this.invalidFiles);
    }

    this.uploadedUrls = this.uploadFileService.getUploadedUrls();
    this.uploadedUrls = this.uploadedUrls.filter(savedUrl => savedUrl !== removedUrl); // Filter using the stored URL value
    this.uploadFileService.setUploadedUrl(this.uploadedUrls);
  }

  onSubmit() {
    if (this.urlInputs.length === 0 || this.urlInputs.every(input => input.value === '')) {
      this.isSubmitted = true;
      this.errorMessage = 'At least one URL is required.';
      return;
    }

    // Check if any of the URLs are not valid (not starting with "https://")
    const hasInvalidURL = this.urlInputs.some(input => !this.isURLValid(input.value));
    if (hasInvalidURL) {
      this.isSubmitted = true;
      this.errorMessage = 'Part of the URL is not formatted correctly!';
      return;
    }

    if (this.form.valid) {
      this.uploadedUrls = this.urlInputs.map(input => input.value).filter(value => value !== '');

      this.uploadFileService.setUploadedUrl(this.uploadedUrls);
      this.dialogRef.close(this.uploadedUrls);
    }
  }

  resetError() {
    this.isSubmitted = false;
  }

  closeForm() {
    this.uploadFileService.setUploadedUrl(this.uploadedUrls);
    this.dialogRef.close(this.uploadedUrls);
  }

  isInvalidURL(url: string): boolean {
    return this.invalidFiles.some(file => file.data_key === url);
  }

  isURLValid(url: string): boolean {
    return url.startsWith('https://') && /\.[^.]{2,}$/.test(url);
  }
}
