<div class="container-fluid">
    <div class="row">
        <div class="col-12 p-4 d-flex" style="justify-content: center; align-items: center; flex-wrap: wrap;">
            <div class="col-12">
                {{ "logout.Are you sure you want to log out?" | translate }}
            </div>
            <div class="col-12 pt-5 mt-3">
                <div class="col-12 d-flex" style="justify-content: space-between; align-items: center;">
                    <button type="button" class="btn p-2" style="background-color: #775da6;" (click)="logout()">
                        {{ "logout.Yes" | translate }}</button>
                    <button type="button" class="btn p-2" style="background-color: #acb4b2;"
                        (click)="closeForgotForm()">{{ "logout.No" | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>