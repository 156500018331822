import { Component } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { SharedService } from '../SharedService';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';
import { AuthService } from 'src/app/AuthService';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  userName: string | undefined;
  companyName: string | undefined;
  formUserInfo: FormGroup;
  formUserPass: FormGroup;
  isSubmittedUserInfo = false;
  isSubmittedUserPass = false;
  idLoadingUserInfo: boolean = false;
  idLoadingUserPass: boolean = false;
  loading = false;
  showPasswordOld = true;
  showPasswordNew = true;
  showPasswordConfirm = true;
  editGeneralDetail: boolean = false;

  subscriptionPlan: any | undefined;
  isLoadingTrialEnd: boolean = false;

  languageMode: string = '';
  errorMessage: string | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private toastrService: ToastrService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
    this.languageMode = this.languageService.getLanguage();

    this.formUserInfo = this.formBuilder.group({
      Name: [' '],
      Email: [''],
      Company: [' '],
    });

    this.formUserPass = this.formBuilder.group({
      PasswordOld: [' '],
      Password: [''],
      ConfirmPassword: [''],
    });
  }

  ngOnInit(): void {
    this.formUserInfo = this.formBuilder.group({
      Name: ['', [Validators.required]],
      Email: ['', [Validators.required, Validators.email]],
      Company: ['', [Validators.required]],
    });

    this.formUserPass = this.formBuilder.group({
      PasswordOld: ['', [Validators.required]],
      Password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[\W_])[A-Za-z\d\W_]+$/),
        ],
      ],
      ConfirmPassword: ['', [Validators.required]],
    });

    this.fetchUserDetails();

    //from api
    this.getuserdetails();
  }

  fetchUserDetails() {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      this.userName = user.name;
      this.companyName = user.company_name;

      this.formUserInfo.patchValue({
        Name: user.name,
        Email: user.email,
        Company: user.company_name,
      });
    }
  }

  setLanguage(language: string) {
    this.languageService.setLanguage(language);
    this.languageMode = language === 'en' ? 'EN' : 'NL';
    this.translate.use(language);
  }

  get errorControlUserInfo() {
    return this.formUserInfo.controls;
  }

  get errorControlUserPass() {
    return this.formUserPass.controls;
  }

  togglePasswordOld() {
    this.showPasswordOld = !this.showPasswordOld;
  }

  togglePasswordNew() {
    this.showPasswordNew = !this.showPasswordNew;
  }

  togglePasswordConfirm() {
    this.showPasswordConfirm = !this.showPasswordConfirm;
  }

  goBack(): void {
    const isSignedUp = localStorage.getItem('isSignedUp') === 'true';
    if (!isSignedUp) {
      const previousPage = this.sharedService.getPreviousActivePage();
      this.sharedService.setActivePage(previousPage);
      window.scrollTo(0, 0);
    } else if (isSignedUp) {
      this.router.navigate(['/choose-subscription']);
    } else {
      this.router.navigate(['/']);
    }
  }

  getuserdetails() {
    this.authService.getuserdetails().subscribe(
      (response) => {
        if (response.data.status === '200' && response.plan !== null) {
          this.subscriptionPlan = response.plan.plan_id - 1;
          console.log(response);
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  manageSubscription(): void {
    if (this.subscriptionPlan === 0) {
      this.trialEnd();
    } else {
      this.subscriptionend();
    }
  }

  trialEnd() {
    this.isLoadingTrialEnd = true;
    this.authService.trialend().subscribe(
      (response) => {
        if (response.data.logs === '200') {
          console.log('Trail End: ', response);
          this.isLoadingTrialEnd = false;
          localStorage.removeItem('isDashboardExpanded');
          window.location.reload();
        }
      },
      (error) => {
        this.isLoadingTrialEnd = false;
        console.error('Error:', error);
      }
    );
  }

  subscriptionend() {
    this.isLoadingTrialEnd = true;
    this.authService.subscriptionend().subscribe(
      (response) => {
        if (response.data.logs === '200') {
          console.log('Subscription End: ', response);
          this.isLoadingTrialEnd = false;
          localStorage.removeItem('isDashboardExpanded');
          window.location.reload();
        }
      },
      (error) => {
        this.isLoadingTrialEnd = false;
        console.error('Error:', error);
      }
    );
  }

  onSubmitInfo(): void {
    this.isSubmittedUserInfo = true;

    if (this.formUserInfo.invalid) {
      return;
    }

    if (this.formUserInfo.status == 'VALID') {
      const name = this.formUserInfo.controls['Name'].value;
      const company = this.formUserInfo.controls['Company'].value;

      this.idLoadingUserInfo = true;
      const requestBody = {
        name: name,
        company_name: company,
      };

      this.authService.userinforchange(requestBody).subscribe(
        (response) => {
          this.idLoadingUserInfo = false;
          this.isSubmittedUserInfo = false;
          // Update localStorage with new user data
          const userData = localStorage.getItem('user');
          let user = null;
          if (userData) {
            user = JSON.parse(userData);
            this.userName = user.name;
            this.companyName = user.company_name;
          }
          user.name = requestBody.name;
          user.company_name = requestBody.company_name;

          // Save the updated user data back to local storage
          localStorage.setItem('user', JSON.stringify(user));

          console.log('User Info update: ', response);
          window.scrollTo(0, 0);

          if (this.languageMode === 'EN') {
            this.toastrService.success('User Info update Successfully!');
          } else {
            this.toastrService.success(
              'Update van gebruikersinformatie succesvol!'
            );
          }
        },
        (error) => {
          this.idLoadingUserPass = false;
          console.error('User Info error:', error);
        }
      );
    }
  }

  onSubmitPass(): void {
    this.isSubmittedUserPass = true;

    if (this.formUserPass.invalid) {
      return;
    }

    if (this.formUserPass.status == 'VALID') {
      const oldpassword = this.formUserPass.controls['PasswordOld'].value;
      const password = this.formUserPass.controls['Password'].value;
      const confirmPassword =
        this.formUserPass.controls['ConfirmPassword'].value;

      if (password !== confirmPassword) {
        this.errorMessage = 'Passwords and Confirm Password must match';
        this.formUserPass.setErrors({ passwordMismatch: true });
        return;
      }

      this.idLoadingUserPass = true;
      const requestBody = {
        oldpassword: oldpassword,
        password: password,
      };

      this.authService.changepassword(requestBody).subscribe(
        (response) => {
          this.idLoadingUserPass = false;
          this.isSubmittedUserPass = false;
          console.log('Password update: ', response);
          this.formUserPass.reset();
          window.scrollTo(0, 0);

          if (this.languageMode === 'EN') {
            this.toastrService.success('Password Changes Successfully!');
          } else {
            this.toastrService.success('Wachtwoordwijzigingen succesvol!');
          }
        },
        (error) => {
          this.idLoadingUserPass = false;
          console.error('Password Update error:', error);
        }
      );
    }
  }
}
