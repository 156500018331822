import { Component } from '@angular/core';
import { helpCenterCardArray } from './help-center-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent {
  userName: string | undefined;
  companyName: string | undefined;
  isCardHovered: number = -1;
  helpCenterCardArray = helpCenterCardArray;

  constructor(private router: Router) { }

  ngOnInit() {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      this.userName = user.name;
      this.companyName = user.company_name;
    }
  }

  onSelectItem(cardId: string) {
    this.router.navigate(['/help-center-question', cardId]);
  }

  onCardHover(index: number) {
    this.isCardHovered = index;
  }
}
