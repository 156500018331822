<div class="container-fluid">
    <div class="row">
        <div class="col-12 pt-3 d-flex" style="justify-content: space-between; align-items: start;">
            <img src="../../../assets/Logo-1-dark.png" width="100px" height="50px" alt="Logo-1-dark">
            <img src="../../../assets/close-btn.svg" (click)="closeForm()" style="cursor: pointer;">
        </div>
    </div>
    <div class="row">
        <div class="col-12 p-md-5 p-4 pt-md-4 mt-md-1 pt-2 d-flex" style="justify-content: center; flex-wrap: wrap;">
            <!-- <div class="col-12 d-flex" style="justify-content: end;">
                <img src="../../../assets/Logo-1-dark.png" width="100px" height="50px" alt="Logo-1-dark">
            </div> -->
            <div class="col-12 col-md-10 d-flex" style="justify-content: center;">
                <div class="col-12 d-flex" style="justify-content: start; flex-wrap: wrap;">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="col-12 col-md-9">
                            <div class="col-12 head">
                                {{ "Gespreks data.Other text data" | translate }}
                            </div>
                            <div class="col-12 pt-1 desc">
                                {{ "Gespreks data.Other text data desc" | translate }}
                            </div>
                            <!-- <div class="col-12 mt-md-4 mt-3 p-2 ps-3 pe-3 input-tab desc"
                                style="font-size: calc(var(font-medium)*1.125); color: #898989; cursor: pointer;"
                                (click)="fileInput.click()">
                                <div class="col-11">
                                    {{ uploadedFileName1 || 'Upload jouw bestand' }}
                                    <input #fileInput id="fileInput" type="file" style="display: none"
                                        (change)="handleFileInput($event)">
                                </div>
                                <div class="col-1 d-flex" style="justify-content: end;">
                                    <img *ngIf="uploadedFileName1" src="../../../assets/check-upload-file-icon.svg"
                                        width="24px" height="24px" alt="check-upload-file-icon">
                                    <img *ngIf="!uploadedFileName1" src="../../../assets/x-upload-file-icon.svg"
                                        width="24px" height="24px" alt="check-upload-file-icon">
                                </div>
                            </div>
                            <div class="col-12 mt-2 desc d-flex" *ngIf="fileErrorMessage1"
                                style="justify-content: center; font-size: calc(var(--font-small));">
                                {{ fileErrorMessage1 }}
                            </div>
                            <div class="col-12 mt-md-4 mt-3 p-2 ps-3 pe-3 input-tab desc"
                                style="font-size: calc(var(font-medium)*1.125); color: #898989; cursor: pointer;"
                                (click)="fileInput2.click()">
                                <div class="col-11">
                                    {{ uploadedFileName2 || 'Upload jouw bestand' }}
                                    <input #fileInput2 id="fileInput2" type="file" style="display: none"
                                        (change)="handleFileInput2($event)">
                                </div>
                                <div class="col-1 d-flex" style="justify-content: end;">
                                    <img *ngIf="uploadedFileName2" src="../../../assets/check-upload-file-icon.svg"
                                        width="24px" height="24px" alt="check-upload-file-icon">
                                    <img *ngIf="!uploadedFileName2" src="../../../assets/x-upload-file-icon.svg"
                                        width="24px" height="24px" alt="check-upload-file-icon">
                                </div>
                            </div> -->
                        </div>
                        <div class="col-12 col-md-10 d-flex"
                            style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                            <div class="col-12" *ngFor="let file of uploadedFiles; let i = index">
                                <div class="col-12 mt-md-4 mt-3 d-flex"
                                    style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                                    <div class="col-11 p-2 ps-3 pe-3 input-tab desc"
                                        [class.invalid-file]="file && isInvalidFile(file.file?.name)"
                                        style="font-size: calc(var(font-medium)*1.125); color: #898989; cursor: pointer;">
                                        <div class="col-11" (click)="fileInput.click()">
                                            <div class="file-name">{{ getFileName(file) }}</div>
                                            <input #fileInput id="fileInput" type="file" formControlName="File"
                                                name="File" style="display: none" (change)="handleFileInput($event, i)"
                                                accept=".pdf, .doc, .docx, .txt">
                                        </div>
                                        <div class="col-1 d-flex" style="justify-content: end;">
                                            <img *ngIf="file.file && !isInvalidFile(file.file?.name)"
                                                src="../../../assets/check-upload-file-icon.svg" width="24px"
                                                height="24px" alt="check">
                                            <img *ngIf="file && isInvalidFile(file.file?.name)"
                                                src="../../../assets/invalid-file-icon.svg" width="24px" height="24px"
                                                alt="X">
                                            <img *ngIf="!file.file" src="../../../assets/x-upload-file-icon.svg"
                                                width="24px" height="24px" alt="X" style="cursor: pointer;"
                                                (click)="removeFile(i)">
                                        </div>
                                    </div>
                                    <div class="col-1 d-flex" style="align-items: center; justify-content: end;">
                                        <img *ngIf="file.file" src="../../../assets/x-upload-file-icon.svg" width="24px"
                                            height="24px" alt="check-upload-file-icon" style="cursor: pointer;"
                                            (click)="removeFileOnly(i)">
                                    </div>
                                </div>
                                <div class="col-12 mt-2 desc" *ngIf="file.error"
                                    style="justify-content: center; font-size: calc(var(--font-small)); color: #f44336;">
                                    {{ file.error }}
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-md-4 mt-3 desc"
                            style="font-size: calc(var(font-medium)*1.125); color: #775da6;">
                            <span style="cursor: pointer;" (click)="addAdditional()">
                                {{ "Gespreks data.+ Add additional file" | translate }}</span>
                        </div>
                        <div class="col-12 mt-md-5 mt-4">
                            <mat-error style="font-size: calc(var(--font-medium));" *ngIf="hasNoFilesUploaded()">
                                {{ "Gespreks data.At least one file is required." | translate }}
                            </mat-error>
                            <button type="submit" class="btn mt-3 p-2 ps-4 pe-4 form-btn d-flex"
                                style="justify-content: center; align-items: center;">
                                {{ "Gespreks data.Upload files" | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>