import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../AuthService';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class startTrainingGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.router.navigate(['/sign-in']);
        }
      }),
      map((authenticated) => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        const isSignedUp = localStorage.getItem('isSignedUp') === 'true';
        const isModelMake = localStorage.getItem('isModelMake') === 'true';
        const isChooseSubscription = localStorage.getItem('isChooseSubscription') === 'true';

        if (isLoggedIn) {
          this.router.navigate(['/sidebar']);
          return false;
        } else if (isSignedUp) {
          this.router.navigate(['/onboarding']);
        } else if (isChooseSubscription) {
          this.router.navigate(['/choose-subscription']);
        }
        return authenticated && isModelMake;
      }),
      catchError(() => {
        this.router.navigate(['/sign-in']);
        return of(false);
      })
    );
  }
}