import {
  Component,
  ViewChild,
  ElementRef,
  QueryList,
  ViewChildren,
  OnInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { uploadFileService } from 'src/app/services/uploadFileService';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-data-upload-text',
  templateUrl: './data-upload-text.component.html',
  styleUrls: ['./data-upload-text.component.scss'],
})
export class DataUploadTextComponent implements OnInit {
  form: FormGroup;
  isSubmitted = false;
  uploadedFiles: { file: File | null; error: string | null }[] = [
    { file: null, error: null },
  ];

  invalidFiles: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<DataUploadTextComponent>,
    private formBuilder: FormBuilder,
    private uploadFileService: uploadFileService,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());

    this.form = this.formBuilder.group({
      File: [''],
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      File: ['', [Validators.required]],
    });

    // Retrieve the previously uploaded files from the data service
    const uploadedFiles = this.uploadFileService.getUploadedFilesT();
    this.uploadedFiles =
      uploadedFiles.length > 0
        ? uploadedFiles.map((file) => ({
            file: file ? file : null,
            error: null,
          }))
        : [{ file: null, error: null }];

    //Retrive invalid files if exist
    this.invalidFiles = this.uploadFileService.getInvalidFiles();
  }

  @ViewChildren('fileInput') fileInputs!: QueryList<any>;

  handleFileInput(event: any, index: number) {
    const file = event.target.files[0];
    const existingFile = this.uploadedFiles[index].file;

    if (existingFile && existingFile.name === file.name) {
      // The same file is being uploaded again
      this.uploadedFiles[index].file = file;
      this.uploadedFiles[index].error = null;
    } else {
      if (this.isValidFileType(file)) {
        this.uploadedFiles[index].file = file;
        this.uploadedFiles[index].error = null;
      } else {
        this.uploadedFiles[index].file = null;
        this.uploadedFiles[index].error =
          'Ongeldig bestandstype. Alleen pdf-, doc- en txt-bestanden zijn toegestaan.';
      }
    }

    // Remove the existing file from invalidFiles if it exists
    if (existingFile) {
      const existingFileName = existingFile.name;
      this.invalidFiles = this.invalidFiles.filter(
        (file) => file.fileName !== existingFileName
      );
      this.uploadFileService.saveInvalidFiles(this.invalidFiles);
    }
  }

  addAdditional() {
    this.uploadedFiles.push({ file: null, error: null });
  }

  removeFile(index: number) {
    this.uploadedFiles.splice(index, 1);
  }

  removeFileOnly(index: number) {
    // Remove the existing file from invalidFiles if it exists
    const existingFile = this.uploadedFiles[index].file;
    if (existingFile) {
      const existingFileName = existingFile.name;
      this.invalidFiles = this.invalidFiles.filter(
        (file) => file.fileName !== existingFileName
      );
      this.uploadFileService.saveInvalidFiles(this.invalidFiles);
    }

    this.uploadedFiles[index].file = null;
    this.uploadedFiles[index].error = null;
    const fileInput = this.fileInputs.toArray()[index];
    if (fileInput) {
      fileInput.nativeElement.value = ''; // Clear the input field value
    }
  }

  isValidFileType(file: File): boolean {
    const allowedTypes = [
      'application/pdf',
      'text/plain',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    return allowedTypes.includes(file.type);
  }

  get errorControl() {
    return this.form.controls;
  }

  getFileName(file: any): string {
    return (
      file?.file?.name ||
      (this.languageService.getLanguage() === 'en'
        ? 'Upload your file'
        : 'Upload jouw bestand')
    );
  }

  onSubmit() {
    this.isSubmitted = true;
    const validFiles = this.uploadedFiles
      .filter((file) => file.file && !file.error)
      .map((file) => file.file);

    if (validFiles.length > 0) {
      // Filter out null values and save the uploaded files to the data service
      const filteredFiles = validFiles.filter(
        (file) => file !== null
      ) as File[];
      this.uploadFileService.setUploadedFilesT(filteredFiles);
      this.dialogRef.close(filteredFiles); // Close the dialog with the filteredFiles
    }
  }

  closeForm() {
    const validFiles = this.uploadedFiles
      .filter((file) => file.file && !file.error)
      .map((file) => file.file);
    const filteredFiles = validFiles.filter((file) => file !== null) as File[];
    this.uploadFileService.setUploadedFilesT(filteredFiles);
    this.dialogRef.close(filteredFiles); // Close the dialog with the filteredFiles
  }

  hasNoFilesUploaded(): boolean {
    return (
      this.isSubmitted &&
      this.errorControl['File']['errors']?.['required'] &&
      this.uploadedFiles.filter((file) => file.file).length === 0
    );
  }

  isInvalidFile(fileName: string | undefined): boolean {
    return (
      fileName !== undefined &&
      this.invalidFiles.some((file) => file.fileName === fileName)
    );
  }
}
