import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/AuthService';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  isSubmitted = false;
  loading = false;
  errorMessage = '';
  sentMessage = '';

  constructor(public dialogRef: MatDialogRef<ForgotPasswordComponent>, private formBuilder: FormBuilder, private authService: AuthService, private translate: TranslateService, private languageService: LanguageService) {
    this.translate.setDefaultLang(this.languageService.getLanguage());

    this.form = this.formBuilder.group({
      Email: ['']
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]]
    });
  }

  get errorControl() {
    return this.form.controls;
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.status == 'VALID') {
      this.loading = true;

      const email = this.form.controls['Email'].value;

      this.authService.forgetpassword(email).subscribe(
        (response) => {
          if (response.message === "email sent") {
            this.loading = false;
            this.sentMessage = 'Password reset details have been sent to your email.';
            this.form.reset();
            this.isSubmitted = false;
            console.log(response);
          } else if (response.data.error === "Email Doesn't Exist") {
            this.errorMessage = response.data.error;
            this.loading = false;
            console.log(response);
          }
        },
        (error) => {
          console.error('Error Forgot Password:', error);
          this.loading = false;
        }
      );
    }
  }

  resetErrorMessage() {
    this.errorMessage = '';
    this.sentMessage = '';
  }

  closeForgotForm() {
    this.dialogRef.close();
  }
}
