<div *ngIf="!isLoggedIn; else LoggedInContent">
    <div class="col-12 d-flex" style="justify-content: center; align-items: center;flex: 1;">
        <div class="col-12 form mt-5 mb-5 d-flex" style="justify-content: center; flex-wrap: wrap;">
            <div class="col-12 col-md-10 col-xl-8 text d-flex"
                style="justify-content: space-between; align-items: start;">
                <p style="cursor: pointer;" (click)="onBack()">
                    <img src="../../../assets/back-arrow-icon.svg" alt="back">
                    <span class="ps-1">{{ "Payment.Back" | translate }}</span>
                </p>
                <div class="dropdown" style="cursor: pointer;">
                    <img class="me-3 info-img dropdown-toggle" src="../../../assets/setting-icon.svg" alt="setting"
                        id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        [class.rotate]="isRotated" (mouseenter)="rotateImage(true)" (mouseleave)="rotateImage(false)">
                    <div class="dropdown-menu dropdown-menu-right py-2" aria-labelledby="dropdownMenuButton">
                        <!-- <div class="col-12 dropdown-item mt-1" routerLink="/profile"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Profile" | translate }}
                        </div> -->
                        <div class="col-12 dropdown-item" (click)="openDocumentation()"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Documentation" | translate }}
                        </div>
                        <!-- <div class="col-12 dropdown-item mt-1" (click)="openHelpCenter()"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Help Center" | translate }}
                        </div> -->
                        <div class="col-12 dropdown-item mt-1" (click)="logout()"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Logout" | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-md-3 mt-2 d-flex" style="justify-content: center;">
                <img class="logo" src="../../../assets/Logo-1-dark.png" alt="Dialect">
            </div>
            <div class="col-12 pt-3 head">
                {{ "Payment.Welcome! to Dialect-AI" | translate }}
            </div>
            <div class="col-12 pt-4 d-flex" *ngIf="price === '€0'" style="justify-content: center;">
                <div class="p-3 notice d-flex" style="align-items: center;">
                    <img src="../../../assets/info-icon-i.svg" width="24px" height="24px">
                    <span class="ms-2 desc">Uw proefabonnement is 14 dagen geldig. Daarna worden er kosten in rekening
                        gebracht!</span>
                </div>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="col-12 pt-5 d-flex" style="justify-content: center; align-items: start; flex-wrap: wrap;">
                    <div class="col-xl-3"></div>
                    <div class="col-12 col-md-9 col-xl-6 d-flex" style="justify-content: center;">
                        <div class="col-11 d-flex"
                            style="justify-content: center; align-items: start; flex-wrap: wrap;">
                            <div class="col-12 p-4 back desc">
                                <div class="col-12 d-flex"
                                    style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                                    <div class="col-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault1" (change)="selectPaymentMethod('creditCard')"
                                                [checked]="selectedPaymentMethod === 'creditCard'">
                                            <label class="form-check-label"
                                                style="font-size: calc(var(--font-medium)*1.2);"
                                                for="flexRadioDefault1">
                                                {{ "Payment.Credit Card" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex" style="justify-content: end;">
                                        <img width="90px" height="24px"
                                            src="../../../assets/visa-and-mastercard-logo.png" alt="card">
                                    </div>
                                </div>
                                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;"
                                    *ngIf="selectedPaymentMethod === 'creditCard'">
                                    <div class="col-12 mt-3 line"></div>
                                    <div class="col-12 pt-3">
                                        {{ "Payment.Card Number.Card Number" | translate }}
                                    </div>
                                    <div class="col-12 pt-2">
                                        <input matInput class="form-control" name="CardNumber" type="text"
                                            placeholder="1234567891112" formControlName="CardNumber" maxlength="16"
                                            (input)="onInputCardNumber($event)" (click)="clearErrorMessage()">
                                    </div>
                                    <div class="col-12 pt-2">
                                        <mat-error style="font-size: calc(var(--font-medium));"
                                            *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CardNumber']['errors']?.['required']">
                                            {{ "Payment.Card Number.required1" | translate }}
                                        </mat-error>
                                        <mat-error style="font-size: calc(var(--font-medium));"
                                            *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CardNumber']['errors']?.['minlength']">
                                            {{ "Payment.Card Number.required2" | translate }}
                                        </mat-error>
                                        <mat-error style="font-size: calc(var(--font-medium));"
                                            *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CardNumber']['errors']?.['maxlength']">
                                            {{ "Payment.Card Number.required3" | translate }}
                                        </mat-error>
                                        <mat-error *ngIf="errorMessage" style="font-size: calc(var(--font-medium));">
                                            {{errorMessage}}
                                        </mat-error>
                                    </div>
                                    <div class="col-12 d-flex"
                                        style="justify-content: space-between; align-items: start; flex-wrap: wrap;">
                                        <div class="col-12 col-md-6">
                                            <div class="col-md-11">
                                                <div class="col-12 pt-3 mt-1">
                                                    {{ "Payment.Experiation date.Experiation date" | translate }}
                                                </div>
                                                <div class="col-12 pt-2">
                                                    <input matInput class="form-control inputM" name="ExperiationDate"
                                                        type="text" placeholder="(MM/YY)" (click)="clearErrorMessage()"
                                                        formControlName="ExperiationDate" maxlength="5">
                                                </div>
                                                <div class="col-12 pt-2">
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['ExperiationDate']['errors']?.['required']">
                                                        {{ "Payment.Experiation date.required1" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['ExperiationDate']['errors']?.['invalidFormat'] && !errorControl['ExperiationDate']['errors']?.['required']">
                                                        {{ "Payment.Experiation date.required2" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['ExperiationDate']['errors']?.['invalidMonth']">
                                                        {{ "Payment.Experiation date.required3" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['ExperiationDate']['errors']?.['invalidYear']">
                                                        {{ "Payment.Experiation date.required4" | translate }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 d-md-flex" style="justify-content: end;">
                                            <div class="col-md-11">
                                                <div class="col-12 pt-3 mt-1">
                                                    {{ "Payment.CVV.CVV" | translate }}
                                                </div>
                                                <div class="col-12 pt-2">
                                                    <input matInput class="form-control inputM" name="CVV" type="text"
                                                        placeholder="123" formControlName="CVV" maxlength="3"
                                                        (input)="onInputCVV($event)" (click)="clearErrorMessage()">
                                                </div>
                                                <div class="col-12 pt-2">
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CVV']['errors']?.['required']">
                                                        {{ "Payment.CVV.required1" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CVV']['errors']?.['minlength']">
                                                        {{ "Payment.CVV.required2" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CVV']['errors']?.['maxlength']">
                                                        {{ "Payment.CVV.required3" | translate }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-4 p-4 back desc">
                                <div class="col-12 d-flex"
                                    style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                                    <div class="col-6">
                                        <div class="form-check" (change)="onRadioChange()">
                                            <input class="form-check-input" type="radio" name="addPromoCode"
                                                id="addPromoCode">
                                            <label class="form-check-label"
                                                style="font-size: calc(var(--font-medium)*1.2);" for="addPromoCode">
                                                {{ "Payment.Promo Code" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex" style="justify-content: end;">
                                        <img width="40px" height="36px" src="../../../assets/promo_code_tag.png"
                                            alt="promo code">
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="isPromoCodeChecked">
                                    <div class="col-12 mt-3 line"></div>
                                    <div class="col-12 pt-3">
                                        {{ "Payment.Promo Code" | translate }}
                                    </div>
                                    <div class="col-12 pt-2 d-flex"
                                        style="justify-content: space-between; align-items: center;">
                                        <div class="col-6">
                                            <input matInput class="form-control" name="promoCode" type="text"
                                                id="promoCode" placeholder="XYZ"
                                                (input)="onInputChangePromoCode($event)"
                                                [class.invalid-promo]="!isPromoCode">
                                        </div>
                                        <div class=" col-6 d-flex" style="justify-content: end;">
                                            <p *ngIf="!isPromoCodeValid"
                                                style="font-weight: bold; color: #775da6; cursor: pointer;"
                                                (click)="applyPromoCode()" [hidden]="isPromoCodeLoading">
                                                {{ "Payment.Apply" | translate }}</p>
                                            <p *ngIf="isPromoCodeValid" style="color: #28A745;">
                                                {{ "Payment.Applied!" | translate }}</p>
                                            <mat-spinner [hidden]="!isPromoCodeLoading" diameter="40"
                                                strokeWidth="5"></mat-spinner>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-12 mt-4 p-4 back desc">
                                <div class="col-12 d-flex"
                                    style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                                    <div class="col-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault1" (change)="selectPaymentMethod('paypal')"
                                                [checked]="selectedPaymentMethod === 'paypal'">
                                            <label class="form-check-label"
                                                style="font-size: calc(var(--font-medium)*1.2);"
                                                for="flexRadioDefault1">
                                                Paypal
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex" style="justify-content: end;">
                                        <img width="90px" height="24px" src="../../../assets/paypal-payment-logo.png"
                                            alt="card">
                                    </div>
                                </div>
                                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;"
                                    *ngIf="selectedPaymentMethod === 'paypal'">
                                    <div class="col-12 mt-3 line"></div>
                                    <div class="col-12 pt-3" style="opacity: 0.8;">
                                        This Payment method is experimental.
                                    </div>
                                    <div class="col-12 pt-3">
                                        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-xl-3 col-12 col-md-6 pt-xl-0 pt-4">
                        <div class="col-12 position_change1">
                            <div class="col-11 col-lg-11 back p-3 d-flex"
                                style="justify-content: center; flex-wrap: wrap;">
                                <div class="col-12 desc d-flex"
                                    style="justify-content: space-between; align-items: center;">
                                    <div class="col-2 col-xl-4">
                                        <img width="80px" height="40px" src="../../../assets/Logo-1-dark.png"
                                            alt="Dialect">
                                    </div>
                                    <div class="col-9 col-xl-8">
                                        <div class="col-12" style="font-size: calc(var(--font-small));">
                                            {{subscriptionType}} <span class="ms-1">
                                                {{ "Payment.Subscription" | translate }}
                                            </span>
                                        </div>
                                        <div class="col-12" style="font-size: calc(var(--font-small)); color: #D8D8D8;">
                                            Dialect . {{subs_duration}}
                                        </div>
                                        <div class="col-12" *ngIf="price !== '€0'"
                                            style="font-size: calc(var(--font-small));">
                                            {{subs_duration_no}}
                                            <span class="ms-2">x</span>
                                            <span class="ms-2">{{price}}</span>
                                        </div>
                                        <div class="col-12" *ngIf="price === '€0'"
                                            style="font-size: calc(var(--font-small));">
                                            <span class="ms-2">{{price}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 pt-5 mt-xl-3" *ngIf="subscriptionType === 'Trail'">
                                    <p style="font-size: calc(var(--font-small));">
                                        {{ "Payment.Your 14 day trial period has begun." | translate }}
                                    </p>
                                </div>
                                <div class="col-12 mt-5 back p-2 d-flex" *ngIf="isPromoCodeValid"
                                    style="justify-content: space-between;">
                                    <span class="d-flex" style="flex-wrap: wrap; align-items: center;">
                                        <span>
                                            <img width="30px" height="30px" src="../../../assets/promo_code_tag.png"
                                                alt="promo code">
                                        </span>
                                        <span *ngIf="isPromoCodeValid" class="ms-2">
                                            <p class="mb-0">{{ "Payment.Promo Applied!" | translate }}</p>
                                        </span>
                                    </span>
                                    <span class="d-flex" (click)="clearPromoCode()"
                                        style="cursor: pointer; align-items: center;">
                                        <img *ngIf="isPromoCodeValid" src="../../../assets/close-icon.svg" width="24px"
                                            height="24px" alt="check-circle-icon">
                                    </span>
                                </div>
                                <div class="col-12 mt-5 back p-2 d-flex" *ngIf="isPromoCodeInValid"
                                    style="justify-content: space-between;">
                                    <span class="d-flex" style="flex-wrap: wrap; align-items: center;">
                                        <span>
                                            <img width="30px" height="30px" src="../../../assets/promo_code_tag.png"
                                                alt="promo code">
                                        </span>
                                        <span *ngIf="!isPromoCodeValid" class="ms-2">
                                            <p class="mb-0">{{ "Payment.Promo not Applicable!" | translate }}</p>
                                        </span>
                                    </span>
                                    <span class="d-flex" (click)="clearPromoCode()"
                                        style="cursor: pointer; align-items: center;">
                                        <img *ngIf="!isPromoCodeValid" src="../../../assets/close-icon.svg" width="24px"
                                            height="24px" alt="check-circle-icon">
                                    </span>
                                </div>
                                <div class="col-12 desc pt-5 mt-xl-3 d-flex" *ngIf="subscriptionType === 'Regular'"
                                    style="justify-content: space-between; font-size: calc(var(--font-small));">
                                    <p>{{ "Payment.Subtotal" | translate }}</p>
                                    <p>€{{subs_total_price}}</p>
                                </div>
                                <div class="col-12 desc pt-1 d-flex" *ngIf="isPromoCodeValid"
                                    style="justify-content: space-between; font-size: calc(var(--font-small));">
                                    <p>{{ "Payment.Discount" | translate }}</p>
                                    <p> - €25.00</p>
                                </div>
                                <div class="col-12 line mt-1"></div>
                                <div class="col-12 desc pt-3 d-flex"
                                    style="justify-content: space-between; font-size: calc(var(--font-small));">
                                    <p>{{ "Payment.Total" | translate }}</p>
                                    <p>€{{subs_total_price}}</p>
                                </div>
                                <div class="col-12 pt-4 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12">
                                        <mat-error *ngIf="errorSubscription"
                                            style="font-size: calc(var(--font-medium));">
                                            {{errorSubscription}}
                                        </mat-error>
                                    </div>
                                    <button type="submit" class="btn mt-3 p-2" style="width: 100%;" [hidden]="loading"
                                        [disabled]="loading" (click)="clearErrorMessage()" *ngIf="!isInvoiceCretead"
                                        [matTooltipPosition]="'above'"
                                        [matTooltip]="isPromoCodeInValid ? 'Zorg ervoor dat het gebruik van de promotiecode geldig is!' : ''">Continue
                                        Payment</button>
                                    <button type="submit" class="btn mt-3 p-2" style="width: 100%;" [hidden]="loading"
                                        [disabled]="loading" (click)="confirmContinue()" *ngIf="isInvoiceCretead">
                                        Confirm Continue</button>
                                    <mat-spinner [hidden]="!loading" diameter="40" strokeWidth="5"></mat-spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<ng-template #LoggedInContent>
    <div class="col-12 d-flex" style="justify-content: center; align-items: center;">
        <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
            <div class="col-12 name-text d-flex" style="justify-content: end; align-items: center; flex-wrap: nowrap;">
                <!-- <img class="me-3 info-img" src="../../../assets/info-icon.svg" alt="info-icon"
                    data-toggle="tooltip" data-placement="right" title="Documentation" routerLink="/docs"> -->
                <!-- <img class="me-3 info-img" src="../../../assets/info-icon.svg" alt="info-icon"> -->
                <span class="language dropdown-toggle me-3" id="dropdownLanguageSelect" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" style="text-transform: uppercase;">
                    {{ languageMode }}
                    <span>
                        <img class="dropdown-rotate" width="20px" height="20px" src="../../../assets/arrow-up-down.svg">
                    </span>
                </span>
                <div class="dropdown-menu dropdown-menu-right py-2" aria-labelledby="dropdownLanguageSelect">
                    <div class="col-12 dropdown-item" (click)="setLanguage('en')"
                        style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                        English
                    </div>
                    <div class="col-12 dropdown-item" (click)="setLanguage('nl')"
                        style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                        Dutch
                    </div>
                </div>
                <p class="pe-3 mb-0">{{userName}} <br>
                    <span style="font-size: calc(var(--font-small)); color: #888a91;">{{companyName}}</span>
                </p>
                <div class="col-12 circle dropdown">
                    <img class="dropdown-toggle" width="36px" height="36px" src="../../../assets/avatar-cartoon.png"
                        data-toggle="tooltip" data-placement="right" title="Profile" id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="dropdown-menu dropdown-menu-right py-2" aria-labelledby="dropdownMenuButton">
                        <div class="col-12 dropdown-item" (click)="notifySidebarActivePage(9)"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Profile" | translate }}
                        </div>
                        <div class="col-12 dropdown-item" (click)="notifySidebarActivePage(2)"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Subscription" | translate }}
                        </div>
                        <div class="col-12 dropdown-item" (click)="openDocumentation()"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Documentation" | translate }}
                        </div>
                        <!-- <div class="col-12 dropdown-item mt-1" (click)="openHelpCenter()"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Help Center" | translate }}
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-12 text d-flex" style="justify-content: start; align-items: start;">
                <p style="cursor: pointer;" (click)="onBack()">
                    <img src="../../../assets/back-arrow-icon.svg" alt="back">
                    <span class="ps-1">{{ "Payment.Back" | translate }}</span>
                </p>
            </div>
            <div class="col-12 mt-md-3 mt-2 d-flex" style="justify-content: center;">
                <img class="logo" src="../../../assets/Logo-1-dark.png" alt="Dialect">
            </div>
            <div class="col-12 pt-3 head">
                {{ "Payment.Welcome! to Dialect-AI" | translate }}
            </div>
            <div class="col-12 pt-4 d-flex" style="justify-content: center;">
                <div class="p-3 notice d-flex" style="align-items: center;">
                    <img *ngIf="subscribePlanID !== planId" src="../../../assets/info-icon-i.svg" width="24px"
                        height="24px">
                    <img *ngIf="subscribePlanID === planId" src="../../../assets/info-icon-i-red.svg" width="24px"
                        height="24px">
                    <span class="ms-2 desc">{{ "Payment." + bannerMessage | translate }}</span>
                </div>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="col-12 pt-4 d-flex" style="justify-content: center; align-items: start; flex-wrap: wrap;">
                    <!-- <div class="col-xl-3"></div> -->
                    <div class="col-12 col-md-9 col-xl-7 col-xxl-6 d-flex" style="justify-content: center;">
                        <div class="col-11 d-flex"
                            style="justify-content: center; align-items: start; flex-wrap: wrap;">
                            <div class="col-12 p-4 back desc">
                                <div class="col-12 d-flex"
                                    style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                                    <div class="col-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault1" (change)="selectPaymentMethod('creditCard')"
                                                [checked]="selectedPaymentMethod === 'creditCard'">
                                            <label class="form-check-label"
                                                style="font-size: calc(var(--font-medium)*1.2);"
                                                for="flexRadioDefault1">
                                                {{ "Payment.Credit Card" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex" style="justify-content: end;">
                                        <img width="90px" height="24px"
                                            src="../../../assets/visa-and-mastercard-logo.png" alt="card">
                                    </div>
                                </div>
                                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;"
                                    *ngIf="selectedPaymentMethod === 'creditCard'">
                                    <div class="col-12 mt-3 line"></div>
                                    <div class="col-12 pt-3">
                                        {{ "Payment.Card Number.Card Number" | translate }}
                                    </div>
                                    <div class="col-12 pt-2">
                                        <input matInput class="form-control" name="CardNumber" type="text"
                                            placeholder="1234567891112" formControlName="CardNumber" maxlength="16"
                                            (input)="onInputCardNumber($event)" (click)="clearErrorMessage()">
                                    </div>
                                    <div class="col-12 pt-2">
                                        <mat-error style="font-size: calc(var(--font-medium));"
                                            *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CardNumber']['errors']?.['required']">
                                            {{ "Payment.Card Number.required1" | translate }}
                                        </mat-error>
                                        <mat-error style="font-size: calc(var(--font-medium));"
                                            *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CardNumber']['errors']?.['minlength']">
                                            {{ "Payment.Card Number.required2" | translate }}
                                        </mat-error>
                                        <mat-error style="font-size: calc(var(--font-medium));"
                                            *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CardNumber']['errors']?.['maxlength']">
                                            {{ "Payment.Card Number.required3" | translate }}
                                        </mat-error>
                                        <mat-error *ngIf="errorMessage" style="font-size: calc(var(--font-medium));">
                                            {{errorMessage}}
                                        </mat-error>
                                    </div>
                                    <div class="col-12 d-flex"
                                        style="justify-content: space-between; align-items: start; flex-wrap: wrap;">
                                        <div class="col-12 col-md-6">
                                            <div class="col-md-11">
                                                <div class="col-12 pt-3 mt-1">
                                                    {{ "Payment.Experiation date.Experiation date" | translate }}
                                                </div>
                                                <div class="col-12 pt-2">
                                                    <input matInput class="form-control inputM" name="ExperiationDate"
                                                        type="text" placeholder="(MM/YY)" (click)="clearErrorMessage()"
                                                        formControlName="ExperiationDate" maxlength="5">
                                                </div>
                                                <div class="col-12 pt-2">
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['ExperiationDate']['errors']?.['required']">
                                                        {{ "Payment.Experiation date.required1" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['ExperiationDate']['errors']?.['invalidFormat'] && !errorControl['ExperiationDate']['errors']?.['required']">
                                                        {{ "Payment.Experiation date.required2" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['ExperiationDate']['errors']?.['invalidMonth']">
                                                        {{ "Payment.Experiation date.required3" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['ExperiationDate']['errors']?.['invalidYear']">
                                                        {{ "Payment.Experiation date.required4" | translate }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 d-md-flex" style="justify-content: end;">
                                            <div class="col-md-11">
                                                <div class="col-12 pt-3 mt-1">
                                                    {{ "Payment.CVV.CVV" | translate }}
                                                </div>
                                                <div class="col-12 pt-2">
                                                    <input matInput class="form-control inputM" name="CVV" type="text"
                                                        placeholder="123" formControlName="CVV" maxlength="3"
                                                        (input)="onInputCVV($event)" (click)="clearErrorMessage()">
                                                </div>
                                                <div class="col-12 pt-2">
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CVV']['errors']?.['required']">
                                                        {{ "Payment.CVV.required1" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CVV']['errors']?.['minlength']">
                                                        {{ "Payment.CVV.required2" | translate }}
                                                    </mat-error>
                                                    <mat-error style="font-size: calc(var(--font-medium));"
                                                        *ngIf="isSubmitted && selectedPaymentMethod === 'creditCard' && errorControl['CVV']['errors']?.['maxlength']">
                                                        {{ "Payment.CVV.required3" | translate }}
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-4 p-4 back desc">
                                <div class="col-12 d-flex"
                                    style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                                    <div class="col-6">
                                        <div class="form-check" (change)="onRadioChange()">
                                            <input class="form-check-input" type="radio" name="addPromoCode"
                                                id="addPromoCode">
                                            <label class="form-check-label"
                                                style="font-size: calc(var(--font-medium)*1.2);" for="addPromoCode">
                                                {{ "Payment.Promo Code" | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex" style="justify-content: end;">
                                        <img width="40px" height="36px" src="../../../assets/promo_code_tag.png"
                                            alt="promo code">
                                    </div>
                                </div>
                                <div class="col-12" *ngIf="isPromoCodeChecked">
                                    <div class="col-12 mt-3 line"></div>
                                    <div class="col-12 pt-3">
                                        {{ "Payment.Promo Code" | translate }}
                                    </div>
                                    <div class="col-12 pt-2 d-flex"
                                        style="justify-content: space-between; align-items: center;">
                                        <div class="col-6">
                                            <input matInput class="form-control" name="promoCode" type="text"
                                                id="promoCode" placeholder="XYZ"
                                                (input)="onInputChangePromoCode($event)"
                                                [class.invalid-promo]="!isPromoCode">
                                        </div>
                                        <div class=" col-6 d-flex" style="justify-content: end;">
                                            <p *ngIf="!isPromoCodeValid"
                                                style="font-weight: bold; color: #775da6; cursor: pointer;"
                                                (click)="applyPromoCode()" [hidden]="isPromoCodeLoading">
                                                {{ "Payment.Apply" | translate }}</p>
                                            <p *ngIf="isPromoCodeValid" style="color: #28A745;">
                                                {{ "Payment.Applied!" | translate }}</p>
                                            <mat-spinner [hidden]="!isPromoCodeLoading" diameter="40"
                                                strokeWidth="5"></mat-spinner>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-12 mt-4 p-4 back desc">
                                <div class="col-12 d-flex"
                                    style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                                    <div class="col-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="flexRadioDefault"
                                                id="flexRadioDefault1" (change)="selectPaymentMethod('paypal')"
                                                [checked]="selectedPaymentMethod === 'paypal'">
                                            <label class="form-check-label"
                                                style="font-size: calc(var(--font-medium)*1.2);"
                                                for="flexRadioDefault1">
                                                Paypal
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex" style="justify-content: end;">
                                        <img width="90px" height="24px" src="../../../assets/paypal-payment-logo.png"
                                            alt="card">
                                    </div>
                                </div>
                                <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;"
                                    *ngIf="selectedPaymentMethod === 'paypal'">
                                    <div class="col-12 mt-3 line"></div>
                                    <div class="col-12 pt-3" style="opacity: 0.8;">
                                        This Payment method is experimental.
                                    </div>
                                    <div class="col-12 pt-3">
                                        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-xl-4 col-xxl-3 col-12 col-md-6 pt-xl-0 pt-4">
                        <div class="col-12 position_change1">
                            <div class="col-11 col-lg-11 back p-3 d-flex"
                                style="justify-content: center; flex-wrap: wrap;">
                                <div class="col-12 desc d-flex"
                                    style="justify-content: space-between; align-items: center;">
                                    <div class="col-2 col-xl-4">
                                        <img width="80px" height="40px" src="../../../assets/Logo-1-dark.png"
                                            alt="Dialect">
                                    </div>
                                    <div class="col-9 col-xl-8">
                                        <div class="col-12" style="font-size: calc(var(--font-small));">
                                            {{subscriptionType}} <span class="ms-1">
                                                {{ "Payment.Subscription" | translate }}
                                            </span>
                                        </div>
                                        <div class="col-12" style="font-size: calc(var(--font-small)); color: #D8D8D8;">
                                            Dialect . {{subs_duration}}
                                        </div>
                                        <div class="col-12" style="font-size: calc(var(--font-small));">
                                            {{subs_duration_no}}
                                            <span class="ms-2">x</span>
                                            <span class="ms-2">{{price}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-5 back p-2 d-flex" *ngIf="isPromoCodeValid"
                                    style="justify-content: space-between;">
                                    <span class="d-flex" style="flex-wrap: wrap; align-items: center;">
                                        <span>
                                            <img width="30px" height="30px" src="../../../assets/promo_code_tag.png"
                                                alt="promo code">
                                        </span>
                                        <span *ngIf="isPromoCodeValid" class="ms-2">
                                            <p class="mb-0">{{ "Payment.Promo Applied!" | translate }}</p>
                                        </span>
                                    </span>
                                    <span class="d-flex" (click)="clearPromoCode()"
                                        style="cursor: pointer; align-items: center;">
                                        <img *ngIf="isPromoCodeValid" src="../../../assets/close-icon.svg" width="24px"
                                            height="24px" alt="check-circle-icon">
                                    </span>
                                </div>
                                <div class="col-12 mt-5 back p-2 d-flex" *ngIf="isPromoCodeInValid"
                                    style="justify-content: space-between;">
                                    <span class="d-flex" style="flex-wrap: wrap; align-items: center;">
                                        <span>
                                            <img width="30px" height="30px" src="../../../assets/promo_code_tag.png"
                                                alt="promo code">
                                        </span>
                                        <span *ngIf="!isPromoCodeValid" class="ms-2">
                                            <p class="mb-0">{{ "Payment.Promo not Applicable!" | translate }}</p>
                                        </span>
                                    </span>
                                    <span class="d-flex" (click)="clearPromoCode()"
                                        style="cursor: pointer; align-items: center;">
                                        <img *ngIf="!isPromoCodeValid" src="../../../assets/close-icon.svg" width="24px"
                                            height="24px" alt="check-circle-icon">
                                    </span>
                                </div>
                                <div class="col-12 desc pt-5 mt-xl-3 d-flex"
                                    style="justify-content: space-between; font-size: calc(var(--font-small));">
                                    <p>{{ "Payment.Subtotal" | translate }}</p>
                                    <p>€{{subs_total_price}}</p>
                                </div>
                                <div class="col-12 desc pt-1 d-flex" *ngIf="isPromoCodeValid"
                                    style="justify-content: space-between; font-size: calc(var(--font-small));">
                                    <p>{{ "Payment.Discount" | translate }}</p>
                                    <p> - €25.00</p>
                                </div>
                                <div class="col-12 line mt-1"></div>
                                <div class="col-12 desc pt-3 d-flex"
                                    style="justify-content: space-between; font-size: calc(var(--font-small));">
                                    <p>{{ "Payment.Total" | translate }}</p>
                                    <p>€{{subs_total_price}}</p>
                                </div>
                                <div class="col-12 pt-4 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12">
                                        <mat-error *ngIf="errorSubscription"
                                            style="font-size: calc(var(--font-medium));">
                                            {{errorSubscription}}
                                        </mat-error>
                                    </div>
                                    <button type="submit" class="btn mt-3 p-2" style="width: 100%;" [hidden]="loading"
                                        [disabled]="loading || subscribePlanID === planId" (click)="clearErrorMessage()"
                                        [matTooltipPosition]="'above'" *ngIf="!isInvoiceCretead"
                                        [matTooltip]="isPromoCodeInValid ? 'Zorg ervoor dat het gebruik van de promotiecode geldig is!' : ''">Continue
                                        Payment</button>
                                    <button type="submit" class="btn mt-3 p-2" style="width: 100%;" [hidden]="loading"
                                        [disabled]="loading" (click)="confirmContinue()" *ngIf="isInvoiceCretead">
                                        Confirm Continue</button>
                                    <mat-spinner [hidden]="!loading" diameter="40" strokeWidth="5"></mat-spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>