import { Component } from '@angular/core';
import { documentation, documentation1, codeResponse, codeListModels } from './documentation-model';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent {
  selectedSectionName: string = 'Introduction';
  selectedSubItems: string = '';
  isCopied: boolean = false;

  // Use the imported arrays
  documentation = documentation;
  documentation1 = documentation1;
  codeResponse = codeResponse;
  codeListModels = codeListModels;

  toggleSubItems(name: string): void {
    if (this.selectedSectionName === name) {
      this.selectedSectionName = name;
    } else {
      this.selectedSectionName = name;
    }

    this.scrollToSection(this.selectedSectionName);
    this.selectedSubItems = '';
  }

  scrollToSection(sectionId: string): void {
    const sectionElement = document.getElementById(sectionId);

    if (sectionElement) {
      const currentYOffset = window.pageYOffset || document.documentElement.scrollTop;
      const sectionTop = sectionElement.getBoundingClientRect().top + currentYOffset;
      if (sectionId === 'Introduction') {
        const yOffset = sectionTop - 140;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      } else {
        const yOffset = sectionTop - 60;
        window.scrollTo({ top: yOffset, behavior: 'smooth' });
      }
    }

    this.selectedSubItems = sectionId;
  }

  copyCodeToClipboard(text: any) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false;
    }, 3000);
  }
}
