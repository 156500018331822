import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'DialectAi-Frontend';

  constructor() {
    // console.log = function (): void {};
    // console.debug = function (): void {};
    // console.warn = function (): void {};
    // console.info = function (): void {};
    // console.error = function (): void {};
  }
}
