<div class="container-fluid">
    <div class="row right-back" [ngSwitch]="currentPage">
        <!-- <ng-container *ngSwitchCase="0">
            <div class="col-12 d-flex" style="justify-content: center; align-items: center; flex: 1;">
                <div class="col-12 col-md-10 col-lg-9 form col-xl-8 col-xxl-7 mt-5 mb-5 d-flex"
                    style="justify-content: center; align-items: center; flex-wrap: wrap;">
                    <div class="col-12 d-flex" style="justify-content: center;">
                        <img class="logo" src="../../../assets/Logo-1-dark.png" alt="Dialect">
                    </div>
                    <div class="col-12 pt-3 head">
                        Welkom! naar Dialect-AI
                    </div>
                    <div class="col-12 pt-5 d-flex"
                        style="justify-content: space-between; align-items: center; flex-wrap: wrap;">
                        <div class="col-md-6 col-12">
                            <div class="col-12 card desc ps-md-5 pe-md-5 p-4 d-flex" (click)="showCurrentTab(0)"
                                [ngClass]="{'active': currentTab === 0}"
                                style="justify-content: center; align-items: center; flex-wrap: wrap;">
                                <div class="col-12" style="font-weight: bold;">
                                    Regular Subscriptions
                                </div>
                                <div class="col-12 pt-3" style="font-size: calc(var(--font-large));">
                                    <p class="mb-0 d-flex">VANAF €50.
                                        <span style="font-size: calc(var(--font-small)); margin-top: -5px;">00</span>
                                    </p>
                                    <p class="ms-5 ps-4"
                                        style="line-height: 16px; font-size: calc(var(--font-extra-small));">EURO/MAAND
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 pt-md-0 pt-4 col-12 d-flex" style="justify-content: end;">
                            <div class="col-12 card desc ps-md-5 pe-md-5 p-4 d-flex" (click)="showCurrentTab(1)"
                                [ngClass]="{'active': currentTab === 1}"
                                style="justify-content: center; align-items: center; flex-wrap: wrap;">
                                <div class="col-12" style="font-weight: bold;">
                                    Trail Subscriptions
                                </div>
                                <div class="col-12 pt-3" style="font-size: calc(var(--font-large));">
                                    <p class="mb-0 d-flex">€15.
                                        <span style="font-size: calc(var(--font-small)); margin-top: -5px;">00</span>
                                    </p>
                                    <p class="ps-3"
                                        style="line-height: 16px; font-size: calc(var(--font-extra-small));">30
                                        DEGAN TRAIL
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-md-flex d-none" style="justify-content: center;">
                        <div class="col-12 back d-flex" style="justify-content: center; flex-wrap: wrap;">
                            <div class="col-md-6 col-12">
                                <div class="col-12 d-flex" style="justify-content: center;">
                                    <span
                                        [ngClass]="{'active-triangle': currentTab === 0, 'triangle': currentTab !== 0}"></span>
                                </div>
                                <div class="col-12 p-4 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12 desc" style="font-weight: bold;">
                                        Dit zit er in je 30 dagen train periode
                                    </div>
                                    <div class="col-12 pt-3 d-flex" *ngFor="let c of trailArray"
                                        style="justify-content: start; align-items: center;">
                                        <img src="../../../assets/check-upload-file-icon.svg">
                                        <span class="ms-3 desc"
                                            style="font-size: calc(var(--font-small))">{{c.name}}</span>
                                    </div>
                                    <div class="col-12 pt-3">
                                        <button type="submit" class="btn ps-4 pe-4 p-2 d-flex"
                                            style="justify-content: center; align-items: center;"
                                            (click)="showCurrentPage(2)" *ngIf="currentTab === 0">GET STARTED</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-12">
                                <div class="col-12 d-flex" style="justify-content: center;">
                                    <span
                                        [ngClass]="{'active-triangle': currentTab === 1, 'triangle': currentTab !== 1}"></span>
                                </div>
                                <div class="col-12 p-4 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12 desc" style="font-weight: bold;">
                                        Enter the new World! met Conersational-AI
                                    </div>
                                    <div class="col-12 pt-3 pb-2">
                                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur, qui quasi?
                                        Optio, incidunt. Quidem, itaque iste.
                                    </div>
                                    <div class="col-12 pt-3 d-flex" *ngFor="let c of subscArray"
                                        style="justify-content: start; align-items: center;">
                                        <img src="../../../assets/check-upload-file-icon.svg">
                                        <span class="ms-3 desc"
                                            style="font-size: calc(var(--font-small))">{{c.name}}</span>
                                    </div>
                                    <div class="col-12 pt-5 mt-1">
                                        <button type="submit" class="btn ps-4 pe-4 p-2 d-flex"
                                            style="justify-content: center; align-items: center;"
                                            (click)="showCurrentPage(1)" *ngIf="currentTab === 1">GET STARTED</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 pt-4 d-flex d-md-none" style="justify-content: center;">
                        <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
                            <div class="col-md-6 back col-12">
                                <div class="col-12 d-flex" style="justify-content: center;">
                                    <span
                                        [ngClass]="{'active-triangle': currentTab === 0, 'triangle': currentTab !== 0}"></span>
                                </div>
                                <div class="col-12 p-4 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12 desc" style="font-weight: bold;">
                                        Dit zit er in je 30 dagen train periode
                                    </div>
                                    <div class="col-12 pt-3 d-flex" *ngFor="let c of trailArray"
                                        style="justify-content: start; align-items: center;">
                                        <img src="../../../assets/check-upload-file-icon.svg">
                                        <span class="ms-3 desc"
                                            style="font-size: calc(var(--font-small))">{{c.name}}</span>
                                    </div>
                                    <div class="col-12 pt-3">
                                        <button type="submit" class="btn ps-4 pe-4 p-2 d-flex"
                                            style="justify-content: center; align-items: center;"
                                            (click)="showCurrentPage(2)" *ngIf="currentTab === 0">GET STARTED</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 back mt-md-0 mt-3 col-12">
                                <div class="col-12 d-flex" style="justify-content: center;">
                                    <span
                                        [ngClass]="{'active-triangle': currentTab === 1, 'triangle': currentTab !== 1}"></span>
                                </div>
                                <div class="col-12 p-4 d-flex" style="justify-content: center; flex-wrap: wrap;">
                                    <div class="col-12 desc" style="font-weight: bold;">
                                        Enter the new World! met Conersational-AI
                                    </div>
                                    <div class="col-12 pt-3 pb-2">
                                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur, qui quasi?
                                        Optio, incidunt. Quidem, itaque iste.
                                    </div>
                                    <div class="col-12 pt-3 d-flex" *ngFor="let c of subscArray"
                                        style="justify-content: start; align-items: center;">
                                        <img src="../../../assets/check-upload-file-icon.svg">
                                        <span class="ms-3 desc"
                                            style="font-size: calc(var(--font-small))">{{c.name}}</span>
                                    </div>
                                    <div class="col-12 pt-5 mt-4">
                                        <button type="submit" class="btn ps-4 pe-4 p-2 d-flex"
                                            style="justify-content: center; align-items: center;"
                                            (click)="showCurrentPage(1)" *ngIf="currentTab === 1">GET STARTED</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container> -->
        <ng-container *ngSwitchCase="1">
            <app-signup3-subscription (buttonClick)="goToPaymentComponent($event)"></app-signup3-subscription>
        </ng-container>
        <ng-container *ngSwitchCase="2">
            <app-signup4-payment [price]="price" [subscriptionType]="subscriptionType" [planId]="planId"
                [subscriptionDuration]="subscriptionDuration" (back)="onBackPage($event)"></app-signup4-payment>
        </ng-container>
        <div class="col-12 d-flex" style="align-items: end; flex: 1;">
            <div class="col-12 footer" style="flex: 1;">
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</div>