import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../AuthService';

@Injectable({
  providedIn: 'root'
})
export class SignupGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(): Observable<boolean> {
    const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const isSignedUp = localStorage.getItem('isSignedUp') === 'true';
    const isModelMake = localStorage.getItem('isModelMake') === 'true';
    const isChooseSubscription = localStorage.getItem('isChooseSubscription') === 'true';

    if (isLoggedIn || isSignedUp || isChooseSubscription || isModelMake) {
      return this.authService.isAuthenticated().pipe(
        tap((authenticated: boolean) => {
          if (authenticated) {
            if (isLoggedIn) {
              this.router.navigate(['/sidebar']);
            } else if (isSignedUp) {
              this.router.navigate(['/onboarding']);
            } else if (isModelMake) {
              this.router.navigate(['/start-training']);
            } else if (isChooseSubscription) {
              this.router.navigate(['/choose-subscription']);
            }
          }
        }),
        catchError(() => {
          this.router.navigate(['/sign-in']);
          return of(false);
        })
      );
    }

    if (!isLoggedIn && !isSignedUp && !isChooseSubscription && !isModelMake) {
      return of(true);
    }

    return of(false);
  }
}
