import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { AuthService } from 'src/app/AuthService';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from 'src/app/form/logout/logout.component';
import { SharedService } from '../SharedService';
import { ICreateOrderRequest } from 'ngx-paypal';
import { environment } from 'src/enviroments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-signup4-payment',
  templateUrl: './signup4-payment.component.html',
  styleUrls: ['./signup4-payment.component.scss'],
})
export class Signup4PaymentComponent implements OnInit {
  form: FormGroup;
  isSubmitted = false;
  loading = false;
  selectedPaymentMethod: string = 'creditCard';
  isRotated: boolean = false;

  @Input() price!: string;
  @Input() subscriptionType: string | undefined;
  @Input() planId: number | undefined;
  @Input() subscriptionDuration: string | undefined;

  isLoggedIn: boolean = false;
  userName: string | undefined;
  companyName: string | undefined;
  subscribePlanID: number | undefined;
  bannerMessage: string = '';
  errorMessage: string = '';
  errorSubscription: string = '';

  public payPalConfig: any;

  subs_duration!: string;
  subs_duration_no!: number;
  subs_price_no!: number;
  subs_total_price!: number;

  subscriptionPackagePlan: string = '';
  isInvoiceCretead: boolean = false;
  languageMode: string = '';

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private ngZone: NgZone,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
    this.languageMode = this.languageService.getLanguage();

    this.form = this.formBuilder.group({
      CardNumber: [''],
      ExperiationDate: [''],
      CVV: [''],
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url === '/onboarding') {
        this.loading = false;
      }
    });

    // Your Stripe public key
    const stripeApiKey = environment.STRIPE_KEY;
    (<any>window).Stripe.setPublishableKey(stripeApiKey);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      CardNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(16),
        ],
      ],
      ExperiationDate: [
        '',
        [Validators.required, this.expirationDateValidator],
      ],
      CVV: [
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(3)],
      ],
    });

    this.form.get('ExperiationDate')?.valueChanges.subscribe((value) => {
      this.formatExpirationDate(value);
    });

    this.isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      this.userName = user.name;
      this.companyName = user.company_name;
    }

    // this.initConfig();

    if (this.isLoggedIn) {
      this.getuserdetails();
    }

    //set subscription duration and according price
    this.subscription_Duration_Price();
  }

  setLanguage(language: string) {
    this.languageService.setLanguage(language);
    this.languageMode = language === 'en' ? 'EN' : 'NL';
    this.translate.use(language);
  }

  get errorControl() {
    return this.form.controls;
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.form.invalid) {
      console.log('Form is invalid');
      return;
    }

    if (this.isPromoCodeInValid === true) {
      console.log('Make sure Promo is valid');
      return;
    }

    if (this.form.status == 'VALID') {
      this.loading = true;

      const cardNumber = this.form.value.CardNumber;
      const expirationDate = this.form.value.ExperiationDate;
      const cvv = this.form.value.CVV;

      (<any>window).Stripe.card.createToken(
        {
          number: cardNumber,
          exp_month: Number(expirationDate.split('/')[0]),
          exp_year: Number(expirationDate.split('/')[1]),
          cvc: cvv,
        },
        (status: number, response: any) => {
          // Wrap Stripe code inside ngZone.run()
          this.ngZone.run(() => {
            console.log(response, status);
            if (status === 200) {
              console.log(
                'Token generated successfully. Token ID:',
                response.id
              );
              this.afterCreateStripeToken(response.id);
            } else {
              this.loading = false;
              console.error('Error generating token:', response.error.message);
              if (status === 402) {
                this.errorMessage = response.error.message;
              }
            }
          });
          //close ngZone
        }
      );
    }
  }

  afterCreateStripeToken(stripeToken: any) {
    this.createUsersubscription(stripeToken);
  }

  createUsersubscription(stripeToken: any) {
    if (this.planId || this.planId === 0) {
      console.log(
        this.planId,
        this.subs_total_price,
        this.subscribePlanID,
        this.promoCode
      );
      this.authService
        .usersubscription(
          this.planId,
          stripeToken,
          this.subs_total_price,
          this.promoCode
        )
        .subscribe(
          (response) => {
            if (response.data.logs === '203') {
              console.log('Invoice created : ', response);
              // Open the invoice URL in a new tab
              window.open(response.invoice_url, '_blank');
              this.getuserInformation();
            } else if (response.data.logs === '202') {
              console.log('User subscription created successfully:', response);
              this.goToOnboardingAtLast();
            } else if (response.data.logs === '500') {
              this.loading = false;
              console.log('User subscription already created:', response);
              this.errorSubscription =
                'Unable to process your payment request!';
            }
          },
          (error) => {
            this.loading = false;
            console.error('Error creating user subscription:', error);
          }
        );
    }
  }

  goToOnboardingAtLast() {
    const tag = 'Onboarding';
    this.authService.setTag(tag).subscribe(
      (response) => {
        console.log('Tag set Response:', response);

        if (!this.isLoggedIn) {
          localStorage.removeItem('isChooseSubscription');
          this.authService.setSignedUp();
          this.router.navigate(['/onboarding']);
        }
        // else {
        //   localStorage.removeItem('isLoggedIn');
        //   localStorage.removeItem('isDashboardExpanded');
        //   this.authService.setSignedUp();
        //   this.router.navigate(['/onboarding']);
        //   window.location.reload();
        // }
      },
      (error) => {
        console.error('Tag set Error:', error);
      }
    );
  }

  getuserInformation() {
    this.authService.getuserdetails().subscribe(
      (response) => {
        if (response.data.status === '200') {
          console.log('User Information : ', response);
          if (
            response.info.subscription_id !== null &&
            response.info.stripetoken !== null
          ) {
            if (!this.isLoggedIn) {
              this.goToOnboardingAtLast();
            } else {
              this.loading = false;
              window.location.reload();
            }
          } else if (response.info.stripetoken !== null) {
            this.loading = false;
            this.isInvoiceCretead = true;
            this.errorSubscription =
              'We have sent you an invoice; please confirm the payment first.';
          }
        }
      },
      (error) => {
        this.loading = false;
        console.error('User Information Error : ', error);
      }
    );
  }

  confirmContinue() {
    this.loading = true;
    this.clearErrorMessage();
    this.getuserInformation();
  }

  selectPaymentMethod(method: string) {
    this.selectedPaymentMethod = method;
    this.form.reset();
    this.isSubmitted = false;
  }

  expirationDateValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const validFormat = /^\d{2}\/\d{2}$/.test(value);
    if (!validFormat) {
      return { invalidFormat: true };
    }

    const [month, year] = value.split('/');
    const currentYear = new Date().getFullYear() % 100;

    // Check if the year is greater than or equal to the current year
    if (Number(year) < currentYear) {
      return { invalidYear: true };
    }

    // Check if the month is between 1 and 12
    if (Number(month) < 1 || Number(month) > 12) {
      return { invalidMonth: true };
    }

    // Return null if the expiration date is valid
    return null;
  }

  formatExpirationDate(value: string) {
    if (!value) {
      return;
    }

    const numericValue = value.replace(/\D/g, '');
    const isDeletingYear = numericValue.length < 4 && value.endsWith('/');

    if (numericValue.length >= 2 && !isDeletingYear) {
      const month = numericValue.slice(0, 2);
      const year = numericValue.slice(2);
      this.form.patchValue(
        { ExperiationDate: `${month}/${year}` },
        { emitEvent: false }
      );
    } else {
      this.form.patchValue(
        { ExperiationDate: numericValue },
        { emitEvent: false }
      );
    }
  }

  onInputCardNumber(event: any) {
    const input = event.target as HTMLInputElement;
    const numericValue = input.value.replace(/\D/g, '');
    input.value = numericValue;
    this.form.controls['CardNumber'].setValue(numericValue);
  }

  onInputCVV(event: any) {
    const input = event.target as HTMLInputElement;
    const numericValue = input.value.replace(/\D/g, '');
    input.value = numericValue;
    this.form.controls['CVV'].setValue(numericValue);
  }

  @Output() back: EventEmitter<any> = new EventEmitter();
  onBack(): void {
    if (!this.isLoggedIn) {
      // if (this.subscriptionType === 'Regular') {
      //   this.back.emit({ currentPage: 0 });
      // } else {
      this.back.emit({ currentPage: 1 });
      // }
    } else {
      this.back.emit({ currentPage: 'Subscription' });
    }
  }

  rotateImage(rotate: boolean) {
    this.isRotated = rotate;
  }

  logout(): void {
    const dialogRef = this.dialog.open(LogoutComponent, {
      disableClose: true,
      backdropClass: 'logout-dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  //user loggenIn

  notifySidebarActivePage(page: number): void {
    this.sharedService.setActivePage(page);
  }

  openDocumentation() {
    const url = '/docs';
    window.open(url, '_blank');
  }

  openHelpCenter() {
    const url = '/help-center';
    window.open(url, '_blank');
  }

  private initConfig(): void {
    const amount = this.price.replace(/[^\d.]/g, '');
    this.payPalConfig = {
      currency: 'EUR',
      clientId: environment.PAYPAL_CLIENT_ID,
      createOrderOnClient: (data: any) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'EUR',
                value: amount,
                breakdown: {
                  item_total: {
                    currency_code: 'EUR',
                    value: amount,
                  },
                },
              },
              items: [
                {
                  name: this.subscriptionType + ' Subscription',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'EUR',
                    value: amount,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
        extraQueryParams: [
          { name: 'disable-funding', value: 'credit,card,venmo' },
        ],
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (
        data: any,
        actions: { order: { get: () => Promise<any> } }
      ) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details: any) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
        });
      },
      onClientAuthorization: (data: any) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
      },
      onCancel: (data: any, actions: any) => {
        console.log('OnCancel', data, actions);
      },
      onError: (err: any) => {
        console.log('OnError', err);
      },
      onClick: (data: any, actions: any) => {
        console.log('onClick', data, actions);
      },
    };
  }

  getuserdetails() {
    this.authService.getuserdetails().subscribe(
      (response) => {
        if (response.data.status === '200' && response.plan !== null) {
          this.subscribePlanID = response.plan.plan_id - 1;
          if (
            this.planId &&
            (this.subscribePlanID ||
              this.subscribePlanID === 0 ||
              this.subscribePlanID === 5)
          ) {
            if (this.planId === this.subscribePlanID) {
              this.bannerMessage =
                'You have already purchased this subscription!';
              console.log(this.bannerMessage);
            } else if (this.planId > this.subscribePlanID) {
              this.bannerMessage =
                'You are going to upgrade your subscription!';
              console.log(this.bannerMessage);
            } else {
              this.bannerMessage =
                'You are going to downgrade your subscription!';
              console.log(this.bannerMessage);
            }
          }
        } else if (response.plan === null) {
          this.bannerMessage =
            'After completing these steps, your subscription is now active!';
        }
        this.checkSubscriptionChange();
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  checkSubscriptionChange() {
    // Define the small and large package IDs
    const smallPackageIds = [0, 1, 2, 5, 6, 7];
    const largePackageIds = [3, 4, 8, 9];

    if (
      this.planId !== undefined &&
      this.subscribePlanID !== undefined &&
      ((smallPackageIds.includes(this.planId) &&
        largePackageIds.includes(this.subscribePlanID)) ||
        (largePackageIds.includes(this.planId) &&
          smallPackageIds.includes(this.subscribePlanID)))
    ) {
      this.subscriptionPackagePlan = 'change';
      console.log('Plan Change: ', this.subscriptionPackagePlan);
    } else {
      this.subscriptionPackagePlan = 'same';
      console.log('Plan Change: ', this.subscriptionPackagePlan);
    }
  }

  subscription_Duration_Price() {
    if (this.subscriptionDuration === 'Monthly') {
      this.subs_duration = '30 days';
      this.subs_duration_no = 1;
    } else {
      this.subs_duration = '365 days';
      this.subs_duration_no = 12;
    }

    // Extract and store the numeric value in subs_price_no
    if (this.price) {
      this.subs_price_no = parseFloat(this.price.replace('€', ''));
    }

    //make total price
    this.subs_total_price = this.subs_duration_no * this.subs_price_no;
  }

  clearErrorMessage() {
    this.errorMessage = '';
    this.errorSubscription = '';
  }

  isPromoCodeChecked: boolean = false;
  promoCode: string = '';
  isPromoCode: boolean = true;
  isPromoCodeValid: boolean = false;
  isPromoCodeInValid: boolean = false;
  isPromoCodeLoading: boolean = false;

  onRadioChange() {
    this.isPromoCodeChecked = !this.isPromoCodeChecked;
  }

  onInputChangePromoCode(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.promoCode = inputElement.value || '';
    this.isPromoCode = true;
  }

  applyPromoCode() {
    this.isPromoCodeValid = false;
    this.isPromoCodeInValid = false;
    if (this.promoCode === '') {
      this.isPromoCode = false;
      console.log(this.isPromoCode);
      return;
    } else {
      this.subscription_Duration_Price();
      this.validpromo();
    }
  }

  validpromo() {
    this.isPromoCodeLoading = true;
    const requestBody = {
      promo_code: this.promoCode,
      planId: this.planId,
    };
    console.log(requestBody);
    this.authService.validpromo(requestBody).subscribe(
      (response) => {
        if (response.data.logs === '200') {
          console.log('Valid Promo: ', response);
          this.subs_total_price -= 25;
          this.isPromoCodeValid = true;
          this.isPromoCodeLoading = false;
        } else {
          console.log('InValid Promo: ', response);
          this.isPromoCode = false;
          this.isPromoCodeLoading = false;
          this.isPromoCodeInValid = true;
        }
      },
      (error) => {
        console.error('Error:', error);
        this.isPromoCode = false;
        this.isPromoCodeLoading = false;
      }
    );
  }

  clearPromoCode(): void {
    this.subscription_Duration_Price();
    this.isPromoCodeValid = false;
    this.isPromoCodeInValid = false;
    this.isPromoCode = true;
    this.promoCode = '';

    const promoCodeInput = document.getElementById(
      'promoCode'
    ) as HTMLInputElement;
    if (promoCodeInput) {
      promoCodeInput.value = '';
    }
  }
}
