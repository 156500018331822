import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { aboutDialectAI, generalQuestions } from '../help-center-model';

@Component({
  selector: 'app-help-center-answer',
  templateUrl: './help-center-answer.component.html',
  styleUrls: ['./help-center-answer.component.scss']
})
export class HelpCenterAnswerComponent implements OnInit {
  userName: string | undefined;
  companyName: string | undefined;
  questions: any[] = [];
  section!: string;
  activeIndex: number = 0;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      this.userName = user.name;
      this.companyName = user.company_name;
    }

    this.route.paramMap.subscribe(params => {
      const section = params.get('section');
      if (section) {
        this.questions = section === 'general' ? generalQuestions : aboutDialectAI;
        this.section = section;
      }
    });
  }

  setActiveIndex(index: number) {
    this.activeIndex = index;
  }
}
