import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/component/SharedService';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  constructor(
    public dialogRef: MatDialogRef<LogoutComponent>,
    private router: Router,
    private translate: TranslateService,
    private languageService: LanguageService,
    private sharedService: SharedService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
  }

  logout(): void {
    localStorage.clear();
    // this.router.navigate(['/']);
    window.location.href = '/';
    // this.dialogRef.close();
    // this.sharedService.resetServices();
  }

  closeForgotForm() {
    this.dialogRef.close();
  }
}
