<div class="col-12 d-flex" style="justify-content: center; align-items: center; flex: 1;">
    <div class="col-12 col-md-11 col-lg-10 col-xl-9 col-xxl-8 mt-5 mb-5 d-flex"
        style="justify-content: center; align-items: center; flex-wrap: wrap;">
        <div class="col-12 text d-flex" style="justify-content: end; align-items: center;">
            <!-- <p style="cursor: pointer;" (click)="onBack()">
                <img src="../../../assets/back-arrow-icon.svg" alt="back">
                <span class="ps-1">Back</span>
            </p> -->
            <span class="language me-4 dropdown-toggle" id="dropdownLanguageSelect" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" style="text-transform: uppercase;">
                {{ languageMode }}
                <span>
                    <img class="dropdown-rotate" width="20px" height="20px" src="../../../assets/arrow-up-down.svg">
                </span>
            </span>
            <div class="dropdown-menu dropdown-menu-right py-2" aria-labelledby="dropdownLanguageSelect">
                <div class="col-12 dropdown-item" (click)="setLanguage('en')"
                    style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                    English
                </div>
                <div class="col-12 dropdown-item" (click)="setLanguage('nl')"
                    style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                    Dutch
                </div>
            </div>
            <div class="dropdown" style="cursor: pointer;">
                <img class="me-3 info-img dropdown-toggle" src="../../../assets/setting-icon.svg" alt="setting"
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    [class.rotate]="isRotated" (mouseenter)="rotateImage(true)" (mouseleave)="rotateImage(false)">
                <div class="dropdown-menu dropdown-menu-right py-2" aria-labelledby="dropdownMenuButton">
                    <!-- <div class="col-12 dropdown-item mt-1" routerLink="/profile"
                            style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                            {{ "common.Profile" | translate }}
                        </div> -->
                    <div class="col-12 dropdown-item" (click)="openDocumentation()"
                        style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                        {{ "common.Documentation" | translate }}
                    </div>
                    <div class="col-12 dropdown-item mt-1" (click)="openHelpCenter()"
                        style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                        {{ "common.Help Center" | translate }}
                    </div>
                    <div class="col-12 dropdown-item mt-1" (click)="logout()"
                        style="color: #151c43; line-height: 22px; font-size: calc(var(--font-medium)); cursor: pointer;">
                        {{ "common.Logout" | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 mt-2 d-flex" style="justify-content: center;">
            <img class="logo" src="../../../assets/Logo-1-dark.png" alt="Dialect">
        </div>
        <div class="col-12 pt-3 head">
            {{ "ChooseSubscription.Welcome! to Dialect-AI" | translate }}
        </div>
        <div class="col-12 pt-4 d-flex" style="justify-content: center; align-items: center; flex-wrap: wrap;">
            <div class="col-12 desc">
                {{ "Subscription.In order to use your agents' APIs, you must first choose a pricing plan." | translate
                }}
            </div>
            <!-- <div class="col-md-6 col-lg-8 col-12 desc">
                {{ "Subscription.In order to use your agents' APIs, you must first choose a pricing plan." | translate
                }}
            </div>
            <div class="col-md-6 col-lg-4 col-12 pt-md-0 pt-2 d-flex" style="justify-content: end;">
                <div class="col-md-7 col-6 tab-back p-1">
                    <div class="col-12 d-flex" style="justify-content: space-between;">
                        <div class="col-6">
                            <button type="button" class="btn" style="width: 97%; border: none; border-radius: 21px;"
                                (click)="showCurrentTab(1)"
                                [ngClass]="{'plan-btn-active': currentTab === 1, 'inactive-btn': currentTab !== 1}">Yearly</button>
                        </div>
                        <div class="col-6 d-flex" style="justify-content: end;">
                            <button type="button" class="btn" style="width: 97%; border: none; border-radius: 21px;"
                                (click)="showCurrentTab(0)"
                                [ngClass]="{'plan-btn-active': currentTab === 0, 'inactive-btn': currentTab !== 0}">Monthly</button>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="col-12 pt-4" [ngSwitch]="currentTab">
            <ng-container *ngSwitchCase="0">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">
                    <div class="col" *ngFor="let c of subscriptionArrayMonth; let i = index;"
                        (mouseenter)="selectCardMonth(i)">
                        <div class="card p-2"
                            [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#151c42' : '#ffffff' }"
                            (click)="selectCardMonth(i)">
                            <div class="card-body" style="display: grid;"
                                [ngStyle]="{ 'color': selectedCardIndexMonth === i ? '#ffffff' : '#7a7a7a' }">
                                <div class="col-12" style="font-size: calc(var(--font-medium)*1.0625);"
                                    [ngStyle]="{ 'color': selectedCardIndexMonth === i ? '#ffffff' : '#999999' }">
                                    {{c.name}}</div>
                                <div class="col-12">
                                    <span
                                        style="line-height: 59px; font-size: calc(var(--font-xxx-large)*1.29); font-weight: bold;"
                                        [ngStyle]="{ 'color': selectedCardIndexMonth === i ? '#ffffff' : '#333333' }">{{c.amount}}</span>
                                    <span style="font-size: calc(var(--font-medium)*1.0625);"
                                        [ngStyle]="{ 'color': selectedCardIndexMonth === i ? '#ffffff' : '#999999' }">
                                        /{{ "Subscription.Month" | translate }}</span>
                                </div>
                                <div class="col-12">
                                    <hr>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt1 | translate }}</span>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt2 | translate }}</span>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt3 | translate }}</span>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt4 | translate }}</span>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt5 | translate }}</span>
                                </div>
                                <div class="col-12 pt-5 d-flex" style="bottom: 0; align-items: end;">
                                    <button class="btn p-2" (click)="goToPayment(c.amount, c.id, 'Regular', 'Monthly')"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#F7F8F9' }">{{c.btn}}</button>
                                </div>
                                <!-- <div class="col-12 pt-5 mt-1 d-flex" *ngIf="i === 0"
                                    style="bottom: 0; align-items: end; flex-wrap: wrap;">
                                    <button type="button" class="btn mt-4 p-2"
                                        (click)="goToPayment(c.amount, c.id, 'Regular', 'Monthly')"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#F7F8F9' }">{{c.btn}}</button>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-4">
                    <div class="card p-2">
                        <div class="card-body">
                            <div class="col-12" style="font-size: calc(var(--font-medium)*1.0625); color: #999999;">
                                {{ "ChooseSubscription.Trail" | translate }}
                            </div>
                            <div class="col-12">
                                <span
                                    style="line-height: 59px; font-size: calc(var(--font-xxx-large)*1.29); font-weight: bold; color: #333333;">€0</span>
                                <!-- <span style="font-size: calc(var(--font-medium)*1.0625); color: #999999;">/Month</span> -->
                            </div>
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12 pt-2">
                                {{ "ChooseSubscription.TrailDesc" | translate }}
                            </div>
                            <div class="col-12 pt-3 d-flex" style="justify-content: space-between;">
                                <div class="col-md-6"></div>
                                <div class="col-12 col-md-6 d-flex" style="justify-content: end;">
                                    <button type="submit" class="btn p-2" style="width: 40%;"
                                        (click)="createSubscriptionWithTrail('€0', 0, 'Trail', 'Monthly')"
                                        [hidden]="isLoadingTrail" [disabled]="isLoadingTrail">
                                        {{ "ChooseSubscription.Get Started" | translate }}</button>
                                    <mat-spinner [hidden]="!isLoadingTrail" diameter="40" strokeWidth="5"></mat-spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="1">
                <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-4">
                    <div class="col" *ngFor="let c of subscriptionArrayYearly; let i = index;"
                        (mouseenter)="selectCardYearly(i)">
                        <div class="card p-2"
                            [ngStyle]="{ 'background-color': selectedCardIndexYearly === i ? '#151c42' : '#ffffff' }"
                            (click)="selectCardYearly(i)">
                            <div class="card-body" style="display: grid;"
                                [ngStyle]="{ 'color': selectedCardIndexYearly === i ? '#ffffff' : '#7a7a7a' }">
                                <div class="col-12" style="font-size: calc(var(--font-medium)*1.0625);"
                                    [ngStyle]="{ 'color': selectedCardIndexYearly === i ? '#ffffff' : '#999999' }">
                                    {{c.name}}</div>
                                <div class="col-12">
                                    <span
                                        style="line-height: 59px; font-size: calc(var(--font-xxx-large)*1.29); font-weight: bold;"
                                        [ngStyle]="{ 'color': selectedCardIndexYearly === i ? '#ffffff' : '#333333' }">{{c.amount}}</span>
                                    <span style="font-size: calc(var(--font-medium)*1.0625);"
                                        [ngStyle]="{ 'color': selectedCardIndexYearly === i ? '#ffffff' : '#999999' }">
                                        /{{ "Subscription.Year" | translate }}</span>
                                </div>
                                <div class="col-12">
                                    <hr>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexYearly === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt1 | translate }}</span>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexYearly === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt2 | translate }}</span>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexYearly === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt3 | translate }}</span>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexYearly === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt4 | translate }}</span>
                                </div>
                                <div class="col-12 pt-2 d-flex" style="align-items: center;" *ngIf="i !== 0">
                                    <span class="point"
                                        [ngStyle]="{ 'background-color': selectedCardIndexYearly === i ? '#ffb91f' : '#151c42' }"></span>
                                    <span class="ms-2">{{ 'Subscription.' + c.pt5 | translate }}</span>
                                </div>
                                <div class="col-12 pt-5 d-flex" *ngIf="i !== 0" style="bottom: 0; align-items: end;">
                                    <button class="btn p-2" (click)="goToPayment(c.amount, c.id, 'Regular', 'Yearly')"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#F7F8F9' }">{{c.btn}}</button>
                                </div>
                                <div class="col-12 pt-5 mt-1 d-flex" *ngIf="i === 0"
                                    style="bottom: 0; align-items: end; flex-wrap: wrap;">
                                    <button type="button" class="btn mt-4 p-2"
                                        (click)="goToPayment(c.amount, c.id, 'Regular', 'Yearly')"
                                        [ngStyle]="{ 'background-color': selectedCardIndexMonth === i ? '#ffb91f' : '#F7F8F9' }">{{c.btn}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 pt-4">
                    <div class="card p-2">
                        <div class="card-body">
                            <div class="col-12" style="font-size: calc(var(--font-medium)*1.0625); color: #999999;">
                                {{ "ChooseSubscription.Trail" | translate }}
                            </div>
                            <div class="col-12">
                                <span
                                    style="line-height: 59px; font-size: calc(var(--font-xxx-large)*1.29); font-weight: bold; color: #333333;">€0</span>
                                <!-- <span style="font-size: calc(var(--font-medium)*1.0625); color: #999999;">/Yearly</span> -->
                            </div>
                            <div class="col-12">
                                <hr>
                            </div>
                            <div class="col-12 pt-2">
                                {{ "ChooseSubscription.TrailDesc" | translate }}
                            </div>
                            <div class="col-12 pt-3 d-flex" style="justify-content: space-between;">
                                <div class="col-md-6"></div>
                                <div class="col-12 col-md-6 d-flex" style="justify-content: end;">
                                    <button type="submit" class="btn p-2" style="width: 40%;"
                                        (click)="createSubscriptionWithTrail('€0', 0, 'Trail', 'Yearly')"
                                        [hidden]="isLoadingTrail" [disabled]="isLoadingTrail">
                                        {{ "ChooseSubscription.Get Started" | translate }}</button>
                                    <mat-spinner [hidden]="!isLoadingTrail" diameter="40" strokeWidth="5"></mat-spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>