import { Component, EventEmitter, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/AuthService';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from 'src/app/form/logout/logout.component';
import {
  subscriptionArrayMonth,
  subscriptionArrayYearly,
} from '../financieel/subscriptionModel';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-signup3-subscription',
  templateUrl: './signup3-subscription.component.html',
  styleUrls: ['./signup3-subscription.component.scss'],
})
export class Signup3SubscriptionComponent {
  currentTab: number = 0;
  selectedCardIndexMonth: number = 2;
  selectedCardIndexYearly: number = 2;
  isRotated: boolean = false;
  isLoadingTrail: boolean = false;

  subscriptionArrayMonth = subscriptionArrayMonth;
  subscriptionArrayYearly = subscriptionArrayYearly;

  languageMode: string = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
    this.languageMode = this.languageService.getLanguage();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url === '/onboarding') {
        this.isLoadingTrail = false;
      }
    });
  }

  setLanguage(language: string) {
    this.languageService.setLanguage(language);
    this.languageMode = language === 'en' ? 'EN' : 'NL';
    this.translate.use(language);
  }

  showCurrentTab(no: number) {
    this.currentTab = no;
  }

  selectCardMonth(index: number) {
    // this.selectedCardIndexMonth = index;
  }

  selectCardYearly(index: number) {
    // this.selectedCardIndexYearly = index;
  }

  @Output() buttonClick: EventEmitter<{
    amount: any;
    id: number;
    type: string;
    duration: string;
  }> = new EventEmitter<{
    amount: any;
    id: number;
    type: string;
    duration: string;
  }>();
  goToPayment(amount: any, id: number, type: string, duration: string) {
    this.buttonClick.emit({ amount, id, type, duration });
  }

  createSubscriptionWithTrail(
    amount: any,
    id: number,
    type: string,
    duration: string
  ) {
    this.isLoadingTrail = true;
    const subs_price = parseFloat(amount.replace('€', ''));
    this.authService.usersubscriptionwithTrail(id, subs_price).subscribe(
      (response) => {
        if (response.data.logs === '202') {
          console.log('Trail subscription created successfully:', response);
          this.goToOnboardingAtLast();
        }
      },
      (error) => {
        this.isLoadingTrail = false;
        console.error('Error creating Trail subscription:', error);
      }
    );
  }

  goToOnboardingAtLast() {
    const tag = 'Onboarding';
    this.authService.setTag(tag).subscribe(
      (response) => {
        console.log('Tag set Response:', response);

        localStorage.removeItem('isChooseSubscription');
        this.authService.setSignedUp();
        this.router.navigate(['/onboarding']);
      },
      (error) => {
        this.isLoadingTrail = false;
        console.error('Tag set Error:', error);
      }
    );
  }

  // @Output() back: EventEmitter<any> = new EventEmitter();
  // onBack(): void {
  //   this.back.emit({ currentPage: 0 });
  // }

  openDocumentation() {
    const url = '/docs';
    window.open(url, '_blank');
  }

  openHelpCenter() {
    const url = '/help-center';
    window.open(url, '_blank');
  }

  rotateImage(rotate: boolean) {
    this.isRotated = rotate;
  }

  logout(): void {
    const dialogRef = this.dialog.open(LogoutComponent, {
      disableClose: true,
      backdropClass: 'logout-dialog-backdrop',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
