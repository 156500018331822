import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/AuthService';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/enviroments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-start-training',
  templateUrl: './start-training.component.html',
  styleUrls: ['./start-training.component.scss']
})
export class StartTrainingComponent implements OnInit, OnDestroy {
  progress: number = 0;
  isTrainingCompleted: boolean = false;
  private progressSubscription: Subscription | undefined;
  loading = false;
  previousProgress: number = 0;

  constructor(private authService: AuthService, private router: Router, private http: HttpClient, private translate: TranslateService, private languageService: LanguageService) {
    this.translate.setDefaultLang(this.languageService.getLanguage());

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url === '/sidebar') {
        this.loading = false;
      }
    });
  }

  ngOnInit() {
    // const isProcessed = localStorage.getItem('isProcessed');
    // if (isProcessed && isProcessed === 'true') {
    //   this.updateProgress();
    // } else {
    //   this.startDocumentProcessing();
    // }
    setInterval(() => {
      if (this.progress < 24) {
        this.progress += 1;
        console.log('Progress: ', this.progress);
      }
    }, 5000);

    this.updateProgress();
  }

  ngOnDestroy() {
    if (this.progressSubscription) {
      this.progressSubscription.unsubscribe();
    }
  }

  startDocumentProcessing() {
    const url = `${environment.API_URL}/process`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http.post(url, null, { headers })
      .subscribe(
        (response) => {
          console.log('Pass:', response);
        },
        (error) => {
          console.error('Fail:', error);
        }
      );

    // Call a separate function to update the progress periodically
    localStorage.setItem('isProcessed', 'true');
    this.updateProgress();
  }

  // async updateProgress() {
  //   const token = localStorage.getItem('token');
  //   const url = 'http://dialectai-usersmodule-env.eba-snbdy89c.eu-west-1.elasticbeanstalk.com/api/getProgress';
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

  //   this.progressSubscription = interval(15000).subscribe(async () => {
  //     try {
  //       const response = await this.http.get<any>(url, { headers }).pipe(take(1)).toPromise();
  //       this.progress = response.progress;
  //       console.log('Progress:', this.progress);

  //       if (this.progress === 25) {
  //         this.isTrainingCompleted = true;
  //         this.progressSubscription?.unsubscribe();
  //       }
  //     } catch (error: any) {
  //       if (error.status === 429) {
  //         // If 429 Too Many Requests error, retry after a delay using recursion
  //         console.error('Error:', error);
  //         const retryAfter = Number(error.headers.get('Retry-After')) || 5000;
  //         this.progressSubscription?.unsubscribe();
  //         setTimeout(() => this.updateProgress(), retryAfter);
  //       } else {
  //         console.error('Error:', error);
  //       }
  //     }
  //   });
  // }

  updateProgress() {
    const url = `${environment.API_URL}/getProgress`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    this.http.get<any>(url, { headers }).pipe(take(1)).toPromise()
      .then((response) => {
        if (response.progress > this.previousProgress) {
          this.previousProgress = this.progress;
          this.progress = response.progress;
        }
        console.log('Progress from response:', response.progress);
        if (this.progress >= 25) {
          this.isTrainingCompleted = true;
          this.progressSubscription?.unsubscribe();
        }
      })
      .catch((error: any) => {
        console.error('Initial API Error:', error);
      });

    this.progressSubscription = interval(15000).subscribe(() => {
      this.http.get<any>(url, { headers }).pipe(take(1)).toPromise()
        .then((response) => {
          if (response.progress > this.previousProgress) {
            this.previousProgress = this.progress;
            this.progress = response.progress;
          }
          console.log('Progress from response:', response.progress);
          if (this.progress >= 25) {
            this.isTrainingCompleted = true;
            this.progressSubscription?.unsubscribe();
          }
        })
        .catch((error: any) => {
          if (error.status === 429) {
            console.error('Error:', error);
            const retryAfter = Number(error.headers.get('Retry-After')) || 5000;
            this.progressSubscription?.unsubscribe();
            setTimeout(() => this.updateProgress(), retryAfter);
          } else {
            console.error('Error:', error);
          }
        });
    });
  }

  goDashboard() {
    this.loading = true;
    if (this.isTrainingCompleted) {
      const tag = 'Set';
      this.authService.setTag(tag).subscribe(
        (response) => {
          console.log('Tag set Response:', response);
        },
        (error) => {
          this.loading = true;
          console.error('Tag set Error:', error);
        }
      );

      this.authService.setLoggedIn();
      localStorage.removeItem('isModelMake');
      localStorage.removeItem('isProcessed');
      this.router.navigate(['/sidebar']);
    }
  }
}