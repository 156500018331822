import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/AuthService';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  form: FormGroup;
  showPassword = true;
  isSubmitted = false;
  errorMessageU = '';
  errorMessageP = '';
  loading = false;
  errorMessage = '';
  isResendEnabled: boolean = false;
  loadingResend = false;
  emailSentMessage = '';

  languageMode: string = '';

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
    this.languageMode = this.languageService.getLanguage();

    this.form = this.formBuilder.group({
      Email: [''],
      Password: [''],
    });

    this.router.events.subscribe((event) => {
      if (
        event instanceof NavigationEnd &&
        (event.url === '/sidebar' ||
          event.url === '/start-training' ||
          event.url === '/onboarding' ||
          event.url === '/choose-subscription')
      ) {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required]],
    });
  }

  get errorControl() {
    return this.form.controls;
  }

  resetErrorMessage(): void {
    this.errorMessageU = '';
    this.errorMessageP = '';
    this.errorMessage = '';
    this.isResendEnabled = false;
    this.emailSentMessage = '';
  }

  onSubmit(): void {
    this.isSubmitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.status == 'VALID') {
      this.loading = true;

      const email = this.form.controls['Email'].value;
      const password = this.form.controls['Password'].value;
      const portal_id = 1;

      const requestBody = {
        email: email,
        password: password,
        portal_id: portal_id,
      };

      this.authService.login(requestBody).subscribe(
        (response) => {
          if (response.data.status === '200') {
            localStorage.clear();
            if (response.user.email_verified === 1) {
              const user = response.user;
              const token = response.token;
              this.authService.saveUserDetails(user);
              this.authService.saveToken(token);
              this.authService.setLoggedIn();

              this.router.navigate(['/sidebar']);
              // this.loading = false;
            } else {
              this.errorMessage = 'Verify your account by checking your email!';
              this.loading = false;
              this.startTimer();
            }
          } else if (response.data.status === '500') {
            if (response.data.logs === "User Doesn't exist") {
              this.errorMessageU = response.data.logs;
              this.loading = false;
            } else {
              this.errorMessageP = response.data.logs;
              this.loading = false;
            }
          }
        },
        (error) => {
          console.error('Login error:', error);
          this.loading = false;
        }
      );
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  forgotPassword() {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {
      width: '800px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  startTimer(): void {
    setTimeout(() => {
      this.isResendEnabled = true;
    }, 6000);
  }

  resendEmail() {
    this.loadingResend = true;
    const email = this.form.controls['Email'].value;

    this.authService.resendverify(email).subscribe(
      (response) => {
        if (response.message === 'email_sent') {
          this.form.reset();
          this.resetErrorMessage();
          this.isSubmitted = false;
          this.loadingResend = false;
          this.emailSentMessage = 'Verify your account by checking your email!';
          console.log(response);
        }
      },
      (error) => {
        console.error('Error send Email:', error);
        this.loadingResend = false;
      }
    );
  }

  setLanguage(language: string) {
    this.languageService.setLanguage(language);
    this.languageMode = language === 'en' ? 'EN' : 'NL';
    this.translate.use(language);
  }
}
