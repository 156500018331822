export const helpCenterCardArray = [
    { "id": "01", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "02", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "03", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "04", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "05", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "06", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "07", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "08", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "09", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "10", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "11", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "12", "name": "Quick Search", "desc": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
];

export const aboutDialectAI = [
    { "id": "01", "q": "How does DialectAI work?", "a": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "02", "q": "Is DialectAI sentient?", "a": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "03", "q": "Is there a human chatting with me?", "a": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
];

export const generalQuestions = [
    { "id": "01", "q": "What is DialectAI?", "a": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "02", "q": "Who created DialectAI?", "a": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "03", "q": "How do I set up my app's notifications?", "a": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "04", "q": "Is DialectAI free?", "a": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
    { "id": "05", "q": "What platforms & devices are supported?", "a": "Lorem ipsum dolor, sit amet consectetur adipisicing elit." },
];