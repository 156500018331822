import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataUploadComponent } from './form/data-upload/data-upload.component';
import { DataUploadUrlComponent } from './form/data-upload-url/data-upload-url.component';
import { DataUploadTextComponent } from './form/data-upload-text/data-upload-text.component';
import { StartTrainingComponent } from './component/start-training/start-training.component';
import { SignInComponent } from './form/sign-in/sign-in.component';
import { SignUpComponent } from './form/sign-up/sign-up.component';

import { AuthGuard } from './auth.guard';
import { CreateAgentComponent } from './form/create-agent/create-agent.component';
import { ForgotPasswordComponent } from './form/forgot-password/forgot-password.component';
import { InvalidFilesComponent } from './form/invalid-files/invalid-files.component';
import { Signup2TrailComponent } from './component/signup2-trail/signup2-trail.component';
import { Signup3SubscriptionComponent } from './component/signup3-subscription/signup3-subscription.component';
import { DocsComponent } from './component/docs/docs.component';
import { DocumentationComponent } from './component/documentation/documentation.component';
import { ApiReferenceComponent } from './component/api-reference/api-reference.component';
import { OverviewComponent } from './component/overview/overview.component';
import { LogoutComponent } from './form/logout/logout.component';
import { HelpCenterComponent } from './component/help-center/help-center.component';
import { HelpCenterQuestionComponent } from './component/help-center/help-center-question/help-center-question.component';
import { HelpCenterAnswerComponent } from './component/help-center/help-center-answer/help-center-answer.component';

import { NgxPayPalModule } from 'ngx-paypal';
import { SharedModuleModule } from './Module/shared-module/shared-module.module';

@NgModule({
  declarations: [
    AppComponent,
    DataUploadComponent,
    DataUploadUrlComponent,
    DataUploadTextComponent,
    StartTrainingComponent,
    SignInComponent,
    SignUpComponent,
    CreateAgentComponent,
    ForgotPasswordComponent,
    InvalidFilesComponent,
    Signup2TrailComponent,
    Signup3SubscriptionComponent,
    DocsComponent,
    DocumentationComponent,
    ApiReferenceComponent,
    OverviewComponent,
    LogoutComponent,
    HelpCenterComponent,
    HelpCenterQuestionComponent,
    HelpCenterAnswerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    NgxPayPalModule,
    MatTooltipModule,
    SharedModuleModule,
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
