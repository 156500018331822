// language.service.ts

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    selectedLanguage: string = 'nl'; // Default language is English

    setLanguage(language: string) {
        // Update the selected language in the service
        this.selectedLanguage = language;
    }

    getLanguage() {
        // Retrieve the selected language from the service
        return this.selectedLanguage;
    }
}
