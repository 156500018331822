<div class="container-fluid p-0">
    <div class="row gx-0">
        <div class="col-12 d-flex" style="justify-content: center; flex-wrap: wrap;">
            <div class="col-12 col-md-4 col-lg-3">
                <div class="col-12 p-4 d-flex left-back" style="justify-content: center; flex-wrap: wrap;">
                    <div class="col-12">
                        <img src="../../../assets/Logo-1-White.png" width="130px" height="60px">
                    </div>
                    <div class="col-12 d-flex" style="align-items: end;">
                        <div class="col-12 d-flex" style="align-items: end; flex-wrap: wrap;">
                            <div class="col-12 head" style="color: #FFFFFF;">
                                {{ "Start Training.Congratulations! It worked! We're going to the training" |
                                translate}}
                            </div>
                            <div class="col-12 pt-2 desc" style="color: #FFFFFF; font-size: calc(var(--font-small));">
                                {{ "Start Training.Your AI Language model is now in training. This takes a few hours." |
                                translate}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-9 right-back d-flex" style="align-items: center;">
                <div class="col-12 d-flex" style="justify-content: center;">
                    <div class="col-11 col-md-8 col-lg-6 d-flex" style="justify-content: center; flex-wrap: wrap;">
                        <div class="col-12 head">
                            {{ "Start Training.Yes, it worked." | translate}}
                        </div>
                        <div class="col-12 pt-2 desc">
                            {{ "Start Training.Yes, it worked. desc" | translate}}
                        </div>
                        <div class="col-12 pt-5">
                            <div class="progress-bar-container">
                                <div class="progress-bar" [style.width]="progress + '%'"></div>
                                <!-- <div class="col-12 pt-4 d-flex" style="justify-content: center;">
                                    <div class="snippet" data-title="dot-flashing">
                                        <div class="stage">
                                            <div class="dot-flashing"></div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-12 pt-5 mt-5">
                            <div class="col-12 desc pb-3" style="color: #28A745;" *ngIf="isTrainingCompleted">
                                {{ "Start Training.The training has been completed." | translate}}
                            </div>
                            <div class="col-12 d-flex" style="justify-content: start; align-items: center; width: 70%;">
                                <button type="submit" class="btn p-2 btn" style="width: 100%;"
                                    [disabled]="!isTrainingCompleted" (click)="goDashboard()" [hidden]="loading"
                                    [disabled]="loading">{{ "Start Training.Go to my Dashboard" | translate}}</button>
                                <mat-spinner [hidden]="!loading" diameter="40" strokeWidth="5"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>