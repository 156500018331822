<div class="container-fluid">
    <div class="row">
        <div class="col-12 pt-3 d-flex" style="justify-content: end; align-items: start;">
            <img src="../../../assets/close-btn.svg" (click)="closeForgotForm()" style="cursor: pointer;">
        </div>
    </div>
    <div class="row">
        <div class="col-12 mt-2 mb-5 d-flex" style="justify-content: center; align-items: center; flex-wrap: wrap;">
            <div class="col-12 col-md-9">
                <div class="col-12 d-flex" style="justify-content: center; align-items: center; flex-wrap: wrap;">
                    <div class="col-12 d-flex" style="justify-content: center;">
                        <img class="logo" src="../../../assets/Logo-1-dark.png" alt="Dialect">
                    </div>
                    <div class="col-12 pt-3 head">
                        {{ "Forgot Password.Forgot your password" | translate }}
                    </div>
                    <div class="col-12 desc pt-2">
                        {{ "Forgot Password.desc" | translate }}
                    </div>
                    <div class="col-12 pt-4 mt-1">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="col-12 d-flex" style="position: relative; align-items: center;">
                                <img class="img" src="../../../assets/useName-icon.svg" alt="Email Icon">
                                <input matInput class="form-control" formControlName="Email" name="Email" type="email"
                                    placeholder="{{ 'Placeholder.Username or email' | translate }}"
                                    (click)="resetErrorMessage()">
                            </div>
                            <div class="col-12 d-flex" style="justify-content: start;">
                                <mat-error class=" pt-2 pb-2" style="font-size: calc(var(--font-medium));"
                                    *ngIf="isSubmitted && errorControl['Email']['errors']?.['required']">
                                    {{ "Forgot Password.Email is required" | translate }}
                                </mat-error>
                            </div>
                            <div class="col-12 pt-4 d-flex" style="justify-content: start;" *ngIf="errorMessage">
                                <mat-error style="font-size: calc(var(--font-medium));"
                                    *ngIf="isSubmitted && errorMessage">
                                    {{ errorMessage }}
                                </mat-error>
                            </div>
                            <div class="col-12 desc pt-4" *ngIf="sentMessage"
                                style="color: #28A745; text-align: start;">
                                {{ "Forgot Password." + sentMessage | translate }}
                            </div>
                            <div class="col-12 pt-4 d-flex" style="justify-content: center; align-items: center;">
                                <div class="col-6">
                                    <button class="btn p-2" type="submit" style="width: 100%;" [hidden]="loading"
                                        [disabled]="loading">{{ "Forgot Password.Forget" | translate }}</button>
                                    <mat-spinner [hidden]="!loading" diameter="40" strokeWidth="5"></mat-spinner>
                                </div>
                                <div class="col-6 desc d-flex" style="justify-content: end; align-items: center;">
                                    <p (click)="closeForgotForm()"
                                        style="color: #775da6; cursor: pointer; font-weight: 700;">
                                        {{ "Forgot Password.Back" | translate }}</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>