import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { LogoutComponent } from 'src/app/form/logout/logout.component';

@Component({
  selector: 'app-signup2-trail',
  templateUrl: './signup2-trail.component.html',
  styleUrls: ['./signup2-trail.component.scss']
})
export class Signup2TrailComponent {
  currentPage: number = 1;
  currentTab: number = 1;
  price: string = '€0';
  subscriptionType: string = 'Regular';
  subscriptionDuration: string | undefined;
  planId: number = 0;
  isRotated: boolean = false;

  constructor(private router: Router, private dialog: MatDialog) { }

  // trailArray = [
  //   { 'id': '01', 'name': '1 elagen taal model' },
  //   { 'id': '02', 'name': 'Maximaal 3 agents' },
  //   { 'id': '03', 'name': 'Full Analytics toegang' },
  //   { 'id': '04', 'name': 'Limited Data Upload' },
  //   { 'id': '05', 'name': 'Maximaal 3 workers' }
  // ];

  // subscArray = [
  //   { 'id': '01', 'name': '€0.002 per boricht' },
  //   { 'id': '02', 'name': '24/7 customer support' }
  // ];

  showCurrentPage(no: number) {
    this.currentPage = no;
    window.scrollTo(0, 0);
  }

  // showCurrentTab(no: number) {
  //   this.currentTab = no;
  // }

  goToPaymentComponent(eventData: { amount: any, id: number, type: string, duration: string }): void {
    this.price = eventData.amount;
    this.planId = eventData.id;
    this.subscriptionType = eventData.type;
    this.subscriptionDuration = eventData.duration;
    this.currentPage = 2;
    window.scrollTo(0, 0);
  }

  onBackPage(event: any) {
    this.currentPage = event.currentPage;
    window.scrollTo(0, 0);
  }

  rotateImage(rotate: boolean) {
    this.isRotated = rotate;
  }

  // logout(): void {
  //   const dialogRef = this.dialog.open(LogoutComponent, {
  //     disableClose: true,
  //     backdropClass: 'logout-dialog-backdrop',
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //   });
  // }
}
