// export const subscriptionArrayMonth = [
//     { 'id': 1, 'name': 'Basic', 'amount': '€30', 'pt1': 'Gedeelde server', 'pt2': 'Tot maximaal 1 agent', 'pt3': '€0,01 per bericht', 'pt4': '24/7 support', 'pt5': '', 'btn': 'Get Basic' },
//     { 'id': 2, 'name': 'Standard', 'amount': '€60', 'pt1': 'Gedeelde server', 'pt2': 'Tot maximaal 3 agent', 'pt3': '€0,01 per bericht', 'pt4': '24/7 support', 'pt5': 'Data Analytics', 'btn': 'Get Standard' },
//     { 'id': 3, 'name': 'Premium', 'amount': '€1000', 'pt1': 'Eigen server', 'pt2': 'Maximaal 1 model', 'pt3': 'Onbeperkt agents', 'pt4': '€0,005 per bericht', 'pt5': 'Data Analytics', 'btn': 'Get Premium' },
//     { 'id': 4, 'name': 'Deluxe', 'amount': '€2000', 'pt1': 'Eigen server', 'pt2': 'Maximaal 3 model', 'pt3': 'Onbeperkt agents', 'pt4': '€0,005 per bericht', 'pt5': 'Data Analytics', 'btn': 'Get Duluxe' },
// ];

export const subscriptionArrayMonth = [
  {
    id: 1,
    name: 'Basic',
    amount: '€11.99',
    pt1: 'Maximum 1 Agent',
    pt2: 'Set up 1 Condition for your agent',
    pt3: 'Unlimited plugins',
    pt4: 'Up to 2000 responses per month',
    pt5: 'Standard email support for assistance',
    btn: 'Get Basic',
  },
  {
    id: 2,
    name: 'Standard',
    amount: '€49.99',
    pt1: 'Maximum 3 Agents',
    pt2: 'Set up 6 Condition for each agent',
    pt3: 'Unlimited plugins',
    pt4: 'Up to 12000 responses per month',
    pt5: 'Standard email support for assistance',
    btn: 'Get Standard',
  },
  {
    id: 3,
    name: 'Premium',
    amount: '€99.9',
    pt1: 'Maximum 7 Agents',
    pt2: 'Set up 10 Conditions for each agent',
    pt3: 'Unlimited plugins',
    pt4: 'Up to 28000 responses per month',
    pt5: 'Premium email support for assistance',
    btn: 'Get Premium',
  },
  // {
  //   id: 4,
  //   name: 'Deluxe',
  //   amount: '€2000',
  //   pt1: 'Eigen server',
  //   pt2: 'Maximaal 3 model',
  //   pt3: 'Onbeperkt agents',
  //   pt4: '€0,005 per bericht',
  //   pt5: 'Data Analytics',
  //   btn: 'Get Duluxe',
  // },
];

export const subscriptionArrayYearly = [
  {
    id: 6,
    name: 'Basic',
    amount: '€25',
    pt1: 'Gedeelde server',
    pt2: 'Tot maximaal 1 agent',
    pt3: '€0,01 per bericht',
    pt4: '24/7 support',
    pt5: '',
    btn: 'Get Basic',
  },
  {
    id: 7,
    name: 'Standard',
    amount: '€50',
    pt1: 'Gedeelde server',
    pt2: 'Tot maximaal 3 agent',
    pt3: '€0,01 per bericht',
    pt4: '24/7 support',
    pt5: 'Data Analytics',
    btn: 'Get Standard',
  },
  {
    id: 8,
    name: 'Premium',
    amount: '€750',
    pt1: 'Eigen server',
    pt2: 'Maximaal 1 model',
    pt3: 'Onbeperkt agents',
    pt4: '€0,005 per bericht',
    pt5: 'Data Analytics',
    btn: 'Get Premium',
  },
  {
    id: 9,
    name: 'Deluxe',
    amount: '€1500',
    pt1: 'Eigen server',
    pt2: 'Maximaal 3 model',
    pt3: 'Onbeperkt agents',
    pt4: '€0,005 per bericht',
    pt5: 'Data Analytics',
    btn: 'Get Duluxe',
  },
];
