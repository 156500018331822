import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/LanguageService';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear: number | undefined;

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService
  ) {
    this.translate.setDefaultLang(this.languageService.getLanguage());
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}
