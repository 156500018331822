<div class="container-fluid">
    <div class="row">
        <div class="col-12 pt-3 d-flex" style="justify-content: space-between; align-items: start;">
            <img src="../../../assets/Logo-1-dark.png" width="100px" height="50px" alt="Logo-1-dark">
            <img src="../../../assets/close-btn.svg" (click)="closeForm()" style="cursor: pointer;">
        </div>
    </div>
    <div class="row">
        <div class="col-12 p-md-5 p-4 pt-md-4 mt-md-1 pt-2 d-flex" style="justify-content: center; flex-wrap: wrap;">
            <!-- <div class="col-12 d-flex" style="justify-content: end;">
                <img src="../../../assets/Logo-1-dark.png" width="100px" height="50px" alt="Logo-1-dark">
            </div> -->
            <div class="col-12 col-md-10 d-flex" style="justify-content: center;">
                <div class="col-12 d-flex" style="justify-content: start; flex-wrap: wrap;">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="col-12 col-md-9">
                            <div class="col-12 head">
                                {{ "Gespreks data.Use your website" | translate }}
                            </div>
                            <div class="col-12 pt-1 desc">
                                <p>
                                    {{ "Gespreks data.Use your website desc1" | translate }}
                                </p>
                                <p>
                                    {{ "Gespreks data.Use your website desc2" | translate }}
                                </p>
                            </div>

                        </div>
                        <div class="col-12 mt-4 d-flex" style="flex-wrap: wrap;">
                            <div class="col-12 desc">
                                {{ "Gespreks data.Enter your website URL here:" | translate }}
                            </div>
                            <!-- <div class="col-12 mt-2 p-2 ps-3 pe-3 input-tab desc"
                                style="font-size: calc(var(font-medium)*1.125); color: #898989;">
                                Voer hier jouw url in.....
                            </div> -->
                            <div class="col-12 mt-3 d-flex" *ngFor="let urlInput of urlInputs; let i = index"
                                style="justify-content: space-between;">
                                <div class="col-11 d-flex" style="position: relative; align-items: center;">
                                    <input matInput class="form-control" [formControl]="urlInput" type="url"
                                        placeholder="{{'Placeholder.Onboarding.Enter your URL here.....' | translate }}"
                                        (click)="resetError()" [class.invalid-url]="isInvalidURL(urlInput.value)">
                                    <img class="eye" *ngIf="isInvalidURL(urlInput.value)"
                                        src="../../../assets/invalid-file-icon.svg" width="24px" height="24px" alt="X">
                                    <img class="eye" alt="X"
                                        *ngIf="urlInput.value && !isInvalidURL(urlInput.value) && !isURLValid(urlInput.value)"
                                        src="../../../assets/x-upload-file-icon-invalid.svg" width="24px" height="24px">
                                    <img class="eye" alt="X"
                                        *ngIf="urlInput.value && !isInvalidURL(urlInput.value) && isURLValid(urlInput.value)"
                                        src="../../../assets/check-upload-file-icon.svg" width="24px" height="24px">
                                </div>
                                <div class="col-1 d-flex" style="align-items: center; justify-content: end;">
                                    <img src="../../../assets/x-upload-file-icon.svg" width="24px" height="24px"
                                        alt="check-upload-file-icon" style="cursor: pointer;"
                                        (click)="removeURLInput(i)">
                                </div>
                            </div>
                            <div class="col-12 mt-3 desc"
                                style="font-size: calc(var(font-medium)*1.125); color: #775da6;">
                                <span style="cursor: pointer;" (click)="addURLInput()">
                                    {{ "Gespreks data.- Exclude URLs from upload" | translate }}</span>
                            </div>
                            <div class="col-12 pt-3">
                                <mat-error style="font-size: calc(var(--font-medium));" *ngIf="isSubmitted">
                                    {{ "Gespreks data." + errorMessage | translate }}
                                </mat-error>
                            </div>
                            <div class="col-12 mt-4 d-flex" style="flex-wrap: wrap-reverse;">
                                <div class="col-12 col-md-6 mt-md-0 mt-2">
                                    <button type="submit" class="btn p-2 ps-4 pe-4 form-btn d-flex"
                                        style="justify-content: center; align-items: center;">
                                        {{ "Gespreks data.Upload files" | translate }}</button>
                                </div>
                                <!-- <div class="col-12 col-md-6 d-flex" style="justify-content: end; align-items: end; ">
                                    <div class="desc"
                                        style="font-size: calc(var(font-medium)*1.125); color: #775da6; cursor: pointer; font-style: italic;">
                                        uitgesloten URL’s bekijken
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>