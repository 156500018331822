import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { helpCenterCardArray, aboutDialectAI, generalQuestions } from '../help-center-model';

@Component({
  selector: 'app-help-center-question',
  templateUrl: './help-center-question.component.html',
  styleUrls: ['./help-center-question.component.scss']
})
export class HelpCenterQuestionComponent implements OnInit {
  userName: string | undefined;
  companyName: string | undefined;
  helpCenterCardId!: string;
  helpCenterCardDetails: any;
  aboutDialectAI = aboutDialectAI;
  generalQuestions = generalQuestions;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      this.userName = user.name;
      this.companyName = user.company_name;
    }

    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id !== null) {
        this.helpCenterCardId = id;
        this.helpCenterCardDetails = helpCenterCardArray.find(card => card.id === this.helpCenterCardId);
      }
    });
  }

  onSectionClick(section: string) {
    this.router.navigate(['/help-center-answer', section]);
  }
}
