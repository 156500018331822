// shared.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private activePageSubject = new BehaviorSubject<number>(0); // Set the default active page to 0 (Dashboard)
  public activePage$ = this.activePageSubject.asObservable();
  private previousActivePage: number = 0;

  private nieuweAgentButtonSubject = new BehaviorSubject<boolean>(false);
  public nieuweAgentButton$ = this.nieuweAgentButtonSubject.asObservable();

  setActivePage(page: number): void {
    this.previousActivePage = this.activePageSubject.value; // Store the previous active page
    this.activePageSubject.next(page);
  }

  getPreviousActivePage(): number {
    return this.previousActivePage;
  }

  setNieuweAgentButtonPressed(): void {
    this.nieuweAgentButtonSubject.next(true);
  }

  setNieuweAgentButtonNotPressed(): void {
    this.nieuweAgentButtonSubject.next(false);
  }

  private isDashboardExpandedSubject = new BehaviorSubject<boolean>(false);
  public isDashboardExpanded$ = this.isDashboardExpandedSubject.asObservable();

  setDashboardExpanded(isExpanded: boolean) {
    this.isDashboardExpandedSubject.next(isExpanded);
    localStorage.setItem('isDashboardExpanded', JSON.stringify(isExpanded));
  }

  //save progess of progress bar from home component
  private progressSubject = new BehaviorSubject<number>(25);
  progress$: Observable<number> = this.progressSubject.asObservable();

  setProgress(progress: number) {
    this.progressSubject.next(progress);
  }

  // make active tab WordPress in integration from plugin customization
  private activeTabSource = new BehaviorSubject<string>('APIs');
  activeTab$ = this.activeTabSource.asObservable();

  setActiveTab(tab: string): void {
    this.activeTabSource.next(tab);
  }

  resetServices(): void {
    // Reset all BehaviorSubjects to their initial values
    this.activePageSubject.next(0);
    this.previousActivePage = 0;
    this.nieuweAgentButtonSubject.next(false);
    this.isDashboardExpandedSubject.next(false);
    this.progressSubject.next(25);
    this.activeTabSource.next('APIs');

    // Also, reset any localStorage or sessionState if used
    localStorage.setItem('isDashboardExpanded', JSON.stringify(false));
  }
}
